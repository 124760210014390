import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack,
  Box,
  Link,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Container,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updatePassword, verify } from '../../utils/authApi';
import { useNavigate, useLocation } from 'react-router-dom';

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};
const RootStyle = styled('div')({
  height: '80vh',
  display: 'grid',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});
const SignupForm = () => {
  const location = useLocation();
  const [issuccess, setIssuccess] = useState(false);
  useEffect(() => {
    verify(
      location.pathname.split('/')[location.pathname.split('/').length - 1]
    )
      .then(({ data }) => {
        setIssuccess(data);
      })
      .catch(() => {});
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const SignupSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const HeadingStyle = styled(Box)({
    textAlign: 'center',
  });
  const formik = useFormik({
    initialValues: {
      passwordConfirmation: '',
      password: '',
    },
    validationSchema: SignupSchema,
    onSubmit: (e) => {
      const res = updatePassword(
        location.pathname.split('/')[location.pathname.split('/').length - 1],
        e.password
      )
        .then(({ data }) => {
          toast.success('Password setting successed!');
          formik.setSubmitting(false);
        })
        .catch((err) => {
          toast.error('Something went wrong.');
          formik.setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <FormikProvider value={formik}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover
            />
            <HeadingStyle>
              <Typography variant="h3" sx={{ color: 'text.secondary', pb: 3 }}>
                {issuccess ? 'Reset Password' : ''}
              </Typography>
              <Typography variant="h5" sx={{ color: 'text.secondary', pb: 3 }}>
                {issuccess ? 'Please update your password' : ''}
              </Typography>
            </HeadingStyle>
            {issuccess ? (
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <Stack
                    spacing={3}
                    initial={{ opacity: 0, y: 40 }}
                    animate={animate}
                  >
                    <TextField
                      fullWidth
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      {...getFieldProps('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />

                    <TextField
                      fullWidth
                      autoComplete="current-confirmpassword"
                      type={showPassword ? 'text' : 'password'}
                      label="Confirm Password"
                      {...getFieldProps('passwordConfirmation')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() => setShowPassword((prev) => !prev)}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(
                        touched.passwordConfirmation &&
                          errors.passwordConfirmation
                      )}
                      helperText={
                        touched.passwordConfirmation &&
                        errors.passwordConfirmation
                      }
                    />
                  </Stack>

                  <Box initial={{ opacity: 0, y: 20 }} animate={animate}>
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                </Stack>
              </Form>
            ) : (
              'Window World'
            )}
          </FormikProvider>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default SignupForm;
