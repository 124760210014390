import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';

import PageWrapper from '../../PageWrapper';
import DrawBox from '../../DrawBox';
import { updateDrawingDataFunc } from '../../../store/slices/measuresheetSlice';

import './style.css';

let data;

const dataForWindowOrder = {
  mainTable: {},
};

const MeasureSheet = () => {
  const measuresheetData = useSelector((state) => state.measuresheet.data);
  data = { ...measuresheetData };
  const windowOrderData = useSelector((state) => state.windoworder.data);

  const viewMode = useSelector((state) => state.option.data.viewMode);

  dataForWindowOrder.mainTable = { ...windowOrderData.mainTable };

  const dispatch = useDispatch();

  const handleSign = (value) => {
    dispatch(updateDrawingDataFunc(value));
  };

  return (
    <PageWrapper addClass="page">
      <div
        className="msh__container flex justify-content__center"
        style={{ width: '250px' }}
      >
        <div className="flex justify-content__space-between width-100">
          <div className="flex justify-content__space-around flex-direction-column measure">
            <div className="measure-sheet__draw-box black-font width-100">
              {measuresheetData.drawingData[0].value ? (
                <div style={{ fontSize: '10px', height: '250px' }}></div>
              ) : (
                <div style={{ fontSize: '10px', height: '250px' }}>
                  Click to Draw
                  <FontAwesomeIcon
                    icon={faPencil}
                    style={{
                      fontSize: 20,
                      color: 'black',
                      margin: '0px 20px',
                    }}
                  />
                </div>
              )}

              <DrawBox
                width={'100%'}
                height={'100%'}
                signId="drawingData"
                addClass="mySign"
                imgInfo={measuresheetData.drawingData[0].value}
                updateSign={handleSign}
                signStatus={true}
                viewMode={viewMode}
                style={measuresheetData.drawingData[0].style}
                index={0}
              />
            </div>
            <div className="measure-sheet__draw-box black-font width-100">
              {measuresheetData.drawingData[1].value ? (
                <div style={{ fontSize: '10px', height: '250px' }}></div>
              ) : (
                <div style={{ fontSize: '10px', height: '250px' }}>
                  Click to Draw
                  <FontAwesomeIcon
                    icon={faPencil}
                    style={{
                      fontSize: 20,
                      color: 'black',
                      margin: '0px 20px',
                    }}
                  />
                </div>
              )}
              <DrawBox
                width={'100%'}
                height={'100%'}
                signId="drawingData"
                addClass="mySign"
                imgInfo={measuresheetData.drawingData[1].value}
                updateSign={handleSign}
                signStatus={true}
                viewMode={viewMode}
                style={measuresheetData.drawingData[1].style}
                index={1}
              />
            </div>
            <div className="measure-sheet__draw-box black-font width-100">
              {measuresheetData.drawingData[2].value ? (
                <div style={{ fontSize: '10px', height: '250px' }}></div>
              ) : (
                <div style={{ fontSize: '10px', height: '250px' }}>
                  Click to Draw
                  <FontAwesomeIcon
                    icon={faPencil}
                    style={{
                      fontSize: 20,
                      color: 'black',
                      margin: '0px 20px',
                    }}
                  />
                </div>
              )}
              <DrawBox
                width={'100%'}
                height={'100%'}
                signId="drawingData"
                addClass="mySign"
                imgInfo={measuresheetData.drawingData[2].value}
                updateSign={handleSign}
                signStatus={true}
                viewMode={viewMode}
                style={measuresheetData.drawingData[2].style}
                index={2}
              />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default MeasureSheet;
