export const styles = () => ({
  root: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    color: '#000133',
  },
  appBar: {
    backgroundColor: '#1976d2',
    color: '#fff',
    height: 65,
  },
  icon: {
    padding: '10px',
    float: 'right',
  },
  title: {
    margin: 'auto',
  },
  container: {
    // display: "flex",
    marginTop: 20,
    flex: 1,
  },
  drawer: {
    backgroundColor: '#fff',
    transition: 'width .2s',
    zIndex: 1,
  },
  closed: {
    width: '0px',
    zIndex: 1,
  },
  opened: {
    width: '250px',
    zIndex: 1,
  },
  main: {
    flex: 1,
    color: 'black',
    minHeight: '80vh',
  },
  buttongroup: {
    marginTop: 60,
  },
  footer: {
    backgroundColor: '#1976d2',
    height: '100px',
    color: '#ffffff',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    width: '100%',
    bottom: 0,
  },
});
