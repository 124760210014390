import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { updateValue } from '../../store/slices/miscellenousSlice';
import { updateValue as updateColor } from '../../store/slices/table33Slice';
import { numberWithCommas, dollarNumberWithCommas } from '../../utils/globals';
import { updateTypeTable } from '../../store/slices/measuresheetSlice';

const MiscellenousTable = (props) => {
  const { pathname } = useLocation();
  const storeData = useSelector((state) => state.miscellenous.data);
  const viewMode = useSelector((state) => state.option.data.viewMode);
  const windowworld = useSelector((state) => state.windowworld.data);
  const vinylsliding = useSelector((state) => state.vinylsliding.data);
  const color = useSelector((state) => state.table33.data.color);
  const dispatch = useDispatch();

  const measuresheetData = useSelector((state) => state.measuresheet.data);
  const data = { ...measuresheetData };

  let flag = useRef(false);

  const input1 = useRef();
  const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();

  useEffect(() => {
    const isContract = (pathname || ' ').indexOf('contract') >= 0;
    if(pathname && !isContract)
    {
      handleChange(
        { target: { value: windowworld.windowTotal } },
        { id: 1, formId: 'miscellenousInput1' }
      );
    }
    if(data.windowTable.tearouts !== "WOOD")
    {
      handleChange(
        { target: { value: windowworld.windowTotal } },
        { id: 4, formId: 'miscellenousInput4' }
      );
    }
    
  }, [windowworld.windowTotal]);
  useEffect(() => {
    const isContract = (pathname || ' ').indexOf('contract') >= 0;
    if(pathname && !isContract)
    {
      handleChange(
        {
          target: {
            value:
              Number(windowworld.windowWorldInput23) +
              Number(windowworld.windowWorldInput24) +
              Number(windowworld.windowWorldInput25),
          },
        },
        { id: 2, formId: 'miscellenousInput2' }
      );
    }
  }, [
    pathname,
    windowworld.windowWorldInput23,
    windowworld.windowWorldInput24,
    windowworld.windowWorldInput25,
  ]);
  useEffect(() => {
    handleChange(
      {
        target: {
          value:
            Number(vinylsliding.vinylSlidingInput1) +
            Number(vinylsliding.vinylSlidingInput2) +
            Number(vinylsliding.vinylSlidingInput3) +
            Number(vinylsliding.vinylSlidingInput4) +
            Number(vinylsliding.vinylSlidingInput5),
        },
      },
      { id: 3, formId: 'miscellenousInput3' }
    );
  }, [
    vinylsliding.vinylSlidingInput1,
    vinylsliding.vinylSlidingInput2,
    vinylsliding.vinylSlidingInput3,
    vinylsliding.vinylSlidingInput4,
    vinylsliding.vinylSlidingInput5,
  ]);
  useEffect(() => {
    let price = 0;
    props.data.map((item) => {
      // if (storeData[`miscellenousInput${item.id}`] > 0) {
        // console.log(storeData[`miscellenousInput${item.id}`], item.unitPrice);
        price += storeData[`miscellenousInput${item.id}`] * item.unitPrice;
      // }
    });
    props.stableUpdateMiscellaneousTable(price);

    if (flag.current === false) {
      input1.current.value = dollarNumberWithCommas(
        storeData['miscellenousInput16']
      );
      input2.current.value = dollarNumberWithCommas(
        storeData['miscellenousInput17']
      );
      input3.current.value = dollarNumberWithCommas(
        storeData['miscellenousInput18']
      );
      input4.current.value = dollarNumberWithCommas(
        storeData['miscellenousInput19']
      );
    }
  }, [storeData]);

  const handleChange = (e, { id, formId }) => {
    if (formId === 'miscellenousInput40' || id === 'miscellenousInput40') {
      dispatch(updateColor({ id: 'color', value: e.target.value }));
      dispatch(
        updateValue({ id: 'miscellenousInput40', count: e.target.value })
      );
      dispatch(updateTypeTable({ ...data.typeTable, trimColor: e.target.value }));
      return;
    }
    if (Number(formId.substr(17, 2)) > 19) {
      dispatch(updateValue({ id: formId, count: e.target.value }));
      return;
    }

    let price = 0;
    props.data.map((item) => {
      let temp = item.id === id ? e.target.value : item.count;
      price += Number(temp) * item.unitPrice;
    });

    if (
      Number(formId.substr(17, 2)) > 15 &&
      Number(formId.substr(17, 2)) < 20
    ) {
      dispatch(updateValue({ id: formId, count: Number(e.target.value) }));
    } else dispatch(updateValue({ id: formId, count: e.target.value }));

    // props.updateMiscellaneousTable({
    //   id: id,
    //   count: e.target.value,
    //   price: price,
    // });
  };

  const handleFocus = (e, id) => {
    e.target.type = 'number';
    e.target.value = storeData[id];
    flag.current = true;
  };

  const handleBlur = (e, id) => {
    e.target.type = 'text';
    e.target.value = dollarNumberWithCommas(Number(storeData[id]));
    flag.current = false;
  };

  return (
    <div className="table-bottom table-font table-padding">
      <div className="table-title-big">
        <strong>MISCELLANEOUS</strong>
      </div>
      <div>
        {props.data.map((item, index) => {
          if (index < 15)
            return (
              <div className="wrapper" key={index}>
                <div
                  className="text-align__left"
                  style={
                    index === 12
                      ? { width: '74%' }
                      : index === 0
                      ? { width: '73%' }
                      : {}
                  }
                >
                  <input
                    id={`miscellenousInput${index + 1}`}
                    type="number"
                    className="bottom-outline width-70px medium-input"
                    onChange={(e) =>
                      handleChange(e, {
                        id: item.id,
                        formId: `miscellenousInput${index + 1}`,
                      })
                    }
                    value={storeData[`miscellenousInput${index + 1}`]}
                    readOnly={viewMode !== 'homepage'}
                  />
                  <label style={index === 12 ? { fontSize: '20px' } : {}}>
                    {item.label}
                  </label>
                  {index === 0 && (
                    <input
                      type="text"
                      className="bottom-outline input-box medium-input"
                      style={
                        viewMode === 'convert-pdf'
                          ? { width: '35%' }
                          : { width: '42%' }
                      }
                      id="miscellenousInput40"
                      onChange={(e) =>
                        handleChange(e, { formId: 'miscellenousInput40' })
                      }
                      value={storeData.miscellenousInput40}
                      readOnly={viewMode !== 'homepage'}
                    />
                  )}
                </div>
                <div>
                  <label>{`$${item.unitPrice}`}</label>
                  <input
                    type="text"
                    style={
                      viewMode === 'convert-pdf'
                        ? { width: '75px' }
                        : { width: '90px' }
                    }
                    className="bottom-outline width-70px medium-input"
                    value={`$ ${numberWithCommas(
                      Number(
                        storeData[`miscellenousInput${index + 1}`] *
                          item.unitPrice
                      )
                    )}`}
                    readOnly
                  />
                </div>
              </div>
            );
        })}
        <div className="wrapper">
          <div>
            <input
              className="bottom-outline width-70px medium-input"
              type="number"
              id="miscellenousInput20"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput20' })
              }
              value={storeData['miscellenousInput20']}
              readOnly={viewMode !== 'homepage'}
            />
            <label>Lift Rental $650 Daily</label>
          </div>
          <div>
            <label>$</label>
            <input
              id="miscellenousInput16"
              className="bottom-outline width-70px medium-input"
              type="text"
              style={
                viewMode === 'convert-pdf'
                  ? { width: '75px' }
                  : { width: '90px' }
              }
              onChange={(e) =>
                handleChange(e, {
                  id: 16,
                  formId: 'miscellenousInput16',
                })
              }
              ref={input1}
              onFocus={(e) => handleFocus(e, 'miscellenousInput16')}
              onBlur={(e) => handleBlur(e, 'miscellenousInput16')}
              // value={numberWithCommas(Number(storeData['miscellenousInput16']))}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
        </div>
        <div className="wrapper">
          <div className="text-align__left" style={{ width: '70%' }}>
            <input
              className="bottom-outline width-70px input-box medium-input"
              type="number"
              id="miscellenousInput21"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput21' })
              }
              value={storeData['miscellenousInput21']}
              readOnly={viewMode !== 'homepage'}
            />
            <label>Extra Labor</label>
            <input
              className="bottom-outline input-box medium-input"
              style={
                viewMode === 'convert-pdf' ? { width: '55%' } : { width: '57%' }
              }
              type="text"
              id="miscellenousInput22"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput22' })
              }
              value={storeData['miscellenousInput22']}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
          <div>
            <label>$</label>
            <input
              id="miscellenousInput17"
              className="bottom-outline width-70px medium-input"
              type="text"
              style={
                viewMode === 'convert-pdf'
                  ? { width: '75px' }
                  : { width: '90px' }
              }
              onChange={(e) =>
                handleChange(e, {
                  id: 17,
                  formId: 'miscellenousInput17',
                })
              }
              ref={input2}
              onFocus={(e) => handleFocus(e, 'miscellenousInput17')}
              onBlur={(e) => handleBlur(e, 'miscellenousInput17')}
              // value={numberWithCommas(Number(storeData['miscellenousInput17']))}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
        </div>
        <div className="wrapper">
          <div className="text-align__left" style={{ width: '70%' }}>
            <input
              className="bottom-outline width-70px medium-input"
              type="number"
              id="miscellenousInput23"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput23' })
              }
              value={storeData['miscellenousInput23']}
              readOnly={viewMode !== 'homepage'}
            />
            <input
              className="bottom-outline width-70px width-70 medium-input"
              style={{ width: '75%' }}
              type="text"
              id="miscellenousInput24"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput24' })
              }
              value={storeData['miscellenousInput24']}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
          <div>
            <label>$</label>
            <input
              id="miscellenousInput18"
              className="bottom-outline width-70px medium-input"
              type="text"
              style={
                viewMode === 'convert-pdf'
                  ? { width: '75px' }
                  : { width: '90px' }
              }
              onChange={(e) =>
                handleChange(e, {
                  id: 18,
                  formId: 'miscellenousInput18',
                })
              }
              ref={input3}
              onFocus={(e) => handleFocus(e, 'miscellenousInput18')}
              onBlur={(e) => handleBlur(e, 'miscellenousInput18')}
              // value={numberWithCommas(Number(storeData['miscellenousInput18']))}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
        </div>
        <div className="wrapper">
          <div className="text-align__left" style={{ width: '70%' }}>
            <input
              className="bottom-outline width-70px medium-input"
              type="number"
              id="miscellenousInput25"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput25' })
              }
              value={storeData['miscellenousInput25']}
              readOnly={viewMode !== 'homepage'}
            />
            <input
              className="bottom-outline width-70 medium-input"
              style={{ width: '75%' }}
              type="text"
              id="miscellenousInput26"
              onChange={(e) =>
                handleChange(e, { formId: 'miscellenousInput26' })
              }
              value={storeData['miscellenousInput26']}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
          <div>
            <label>$</label>
            <input
              id="miscellenousInput19"
              className="bottom-outline width-70px medium-input"
              type="text"
              style={
                viewMode === 'convert-pdf'
                  ? { width: '75px' }
                  : { width: '90px' }
              }
              onChange={(e) =>
                handleChange(e, {
                  id: 19,
                  formId: 'miscellenousInput19',
                })
              }
              ref={input4}
              onFocus={(e) => handleFocus(e, 'miscellenousInput19')}
              onBlur={(e) => handleBlur(e, 'miscellenousInput19')}
              // value={numberWithCommas(Number(storeData['miscellenousInput19']))}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiscellenousTable;
