export const data = {
  contact: {
    customer: 'customer1',
    installAddr: 'install address',
    billAddr: 'bill address',
    phone1: 'phone1',
    phone2: 'phone2',
  },

  windowWorldTableData: [
    {
      id: 1,
      count: 0,
      label: '4000 Series Double Hung',
      unitPrice: 470,
      price: 0,
    },
    {
      id: 2,
      count: 0,
      label: '4000 Series Slider',
      unitPrice: 773,
      price: 0,
    },
    {
      id: 3,
      count: 0,
      label: '4000 Series 3 Lite Slider (based on 132-178 ui)',
      unitPrice: 1040,
      price: 0,
    },
    {
      id: 4,
      count: 0,
      label: '4000 Picture Window (based on 96-121)',
      unitPrice: 589,
      price: 0,
    },
    {
      id: 5,
      count: 0,
      label: '4000 Series Transom',
      unitPrice: 410,
      price: 0,
    },
    {
      id: 6,
      count: 0,
      label: '6000 Series Double Hung',
      unitPrice: 570,
      price: 0,
    },
    {
      id: 7,
      count: 0,
      label: '6000 Series 2 Lite Slider',
      unitPrice: 870,
      price: 0,
    },
    {
      id: 8,
      count: 0,
      label: '6000 Series 3 Lite Slider',
      unitPrice: 1140,
      price: 0,
    },
    {
      id: 9,
      count: 0,
      label: '6000 Series Picture Window',
      unitPrice: 730,
      price: 0,
    },
    {
      id: 10,
      count: 0,
      label: 'Oversized window upcharge (102-120)',
      unitPrice: 80,
      price: 0,
    },
    {
      id: 11,
      count: 0,
      label: 'Oversized window upcharge (121-156)',
      unitPrice: 120,
      price: 0,
    },
    {
      id: 12,
      count: 0,
      label: 'Awning',
      unitPrice: 602,
      price: 0,
    },
    {
      id: 13,
      count: 0,
      label: 'Casement',
      unitPrice: 615,
      price: 0,
    },
    {
      id: 14,
      count: 0,
      label: '2 Lite Casement',
      unitPrice: 1230,
      price: 0,
    },
    {
      id: 15,
      count: 0,
      label: '3 Lite Casement',
      unitPrice: 1845,
      price: 0,
    },
    {
      id: 16,
      count: 0,
      label: 'Basement Hopper',
      unitPrice: 450,
      price: 0,
    },
    {
      id: 17,
      count: 0,
      label: 'Bay/Bow Window',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 18,
      count: 0,
      label: 'Specialty Window',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 19,
      count: 0,
      label: 'Specialty Window',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 20,
      count: 0,
      label: 'Specialty Window',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 21,
      count: 0,
      label: 'Install Labor',
      unitPrice: 1,
      price: 0,
    },
    // {
    //   id: 22,
    //   signature: ' ',
    // },
  ],

  glassTableData: [
    {
      id: 1,
      count: 0,
      label: 'SolarZone',
      unitPrice: 150,
      price: 0,
    },
    {
      id: 2,
      count: 0,
      label: 'SolarZone TG2 Triple Pane',
      unitPrice: 246,
      price: 0,
    },
  ],

  prebuiltTableData: [
    {
      id: 1,
      count: 0,
      label: 'Lead Renovation Fee',
      unitPrice: 50,
      price: 0,
    },
    // {
    //   id: 2,
    //   label: 'MY HOME WAS BUILT IN THE YEAR',
    //   signature: ' ',
    // },
  ],

  windowOptionTableData: [
    {
      id: 1,
      count: 0,
      label: 'Almond/Clay Extruded',
      unitPrice: 65,
      price: 0,
    },
    {
      id: 2,
      count: 0,
      label: 'Grids',
      unitPrice: 79,
      price: 0,
    },
    {
      id: 3,
      count: 0,
      label: 'Obscure Glass (top or bottom)',
      unitPrice: 50,
      price: 0,
    },
    {
      id: 4,
      count: 0,
      label: 'Prairie Grids (Top and bottom)',
      unitPrice: 118,
      price: 0,
    },
    {
      id: 5,
      count: 0,
      label: 'Full Screens (Extruded or Flexscreen)',
      unitPrice: 50,
      price: 0,
    },
    {
      id: 6,
      count: 0,
      label: 'Wood Grain Interior',
      unitPrice: 154,
      price: 0,
    },
    {
      id: 7,
      count: 0,
      label: 'Colored Exterior',
      unitPrice: 225,
      price: 0,
    },
    {
      id: 8,
      count: 0,
      label: 'Foam Enhanced DH Only',
      unitPrice: 45,
      price: 0,
    },
    {
      id: 9,
      count: 0,
      label: 'Tempered Glass DH (top or bottom)',
      unitPrice: 105,
      price: 0,
    },
    {
      id: 10,
      count: 0,
      label: 'Tempered Glass per sq ft.',
      unitPrice: 16,
      price: 0,
    },
    {
      id: 11,
      count: 0,
      label: 'Oriel Style 60/40 / Cottage 40/60',
      unitPrice: 75,
      price: 0,
    },
  ],

  miscellaneousTableData: [
    {
      id: 1,
      count: 0,
      label: 'Custom Exterior Trim',
      unitPrice: 125,
      price: 0,
    },
    {
      id: 2,
      count: 0,
      label: 'Exterior Trim-Specialty Windows',
      unitPrice: 200,
      price: 0,
    },
    {
      id: 3,
      count: 0,
      label: 'Patio Door Exterior Trim',
      unitPrice: 200,
      price: 0,
    },
    {
      id: 4,
      count: 0,
      label: 'Aluminum,Steel or Vinyl Removal Inc. RWD',
      unitPrice: 75,
      price: 0,
    },
    {
      id: 5,
      count: 0,
      label: 'Mull to Form Multi Unit or Mull Removal',
      unitPrice: 40,
      price: 0,
    },
    {
      id: 6,
      count: 0,
      label: 'Buck Frame (Jamb)',
      unitPrice: 75,
      price: 0,
    },
    {
      id: 7,
      count: 0,
      label: 'Interior Casing -Primed Standard 2”',
      unitPrice: 125,
      price: 0,
    },
    {
      id: 8,
      count: 0,
      label: 'Complete Exterior Sill',
      unitPrice: 150,
      price: 0,
    },
    {
      id: 9,
      count: 0,
      label: 'Repair Sill, Jamb, or Brick Mold',
      unitPrice: 75,
      price: 0,
    },
    {
      id: 10,
      count: 0,
      label: 'Vinyl Interior Trim (RWD)',
      unitPrice: 50,
      price: 0,
    },
    {
      id: 11,
      count: 0,
      label: 'J-Channel',
      unitPrice: 60,
      price: 0,
    },
    {
      id: 12,
      count: 0,
      label: 'Nail Fins',
      unitPrice: 35,
      price: 0,
    },
    {
      id: 13,
      count: 0,
      label: 'Storm Door Standard Size (Includes Hardware)',
      unitPrice: 699,
      price: 0,
    },
    {
      id: 14,
      count: 0,
      label: 'Steel Security / Secure Glass Storm Door',
      unitPrice: 1199,
      price: 0,
    },
    {
      id: 15,
      count: 0,
      label: 'Custom Size Storm Door Option',
      unitPrice: 300,
      price: 0,
    },
    {
      id: 16,
      count: 0,
      label: 'Lift Rental $650 Daily',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 17,
      count: 0,
      label: 'Extra Labor',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 18,
      count: 0,
      label: '',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 19,
      count: 0,
      label: '',
      unitPrice: 1,
      price: 0,
    },
  ],

  vinylslidingTableData: [
    {
      id: 1,
      count: 0,
      label: '6ft. Patio Door Includes Build-In',
      unitPrice: 2595,
      price: 0,
    },
    {
      id: 2,
      count: 0,
      label: '8ft. Patio Door Includes Build-In',
      unitPrice: 2895,
      price: 0,
    },
    {
      id: 3,
      count: 0,
      label: '9ft. Patio Door Includes Build-In',
      unitPrice: 3695,
      price: 0,
    },
    {
      id: 4,
      count: 0,
      label: 'Custom Size Door Option',
      unitPrice: 600,
      price: 0,
    },
    {
      id: 5,
      count: 0,
      label: 'Contemporary 5” or French Rail 7” Option (AMI Only)',
      unitPrice: 300,
      price: 0,
    },
    {
      id: 6,
      count: 0,
      label: 'Interior Woodgrain',
      unitPrice: 409,
      price: 0,
    },
    {
      id: 7,
      count: 0,
      label: 'Exterior Color',
      unitPrice: 549,
      price: 0,
    },
    {
      id: 8,
      count: 0,
      label: 'Grids',
      unitPrice: 191,
      price: 0,
    },
    {
      id: 9,
      count: 0,
      label: 'Prairie Grids',
      unitPrice: 200,
      price: 0,
    },
    {
      id: 10,
      count: 0,
      label: 'Almond/Clay color extruded (2 or 3 panel)',
      unitPrice: 199,
      price: 0,
    },
    {
      id: 11,
      count: 0,
      label: 'Keyed Entry',
      unitPrice: 50,
      price: 0,
    },
    {
      id: 12,
      count: 0,
      label: 'Upgrade Handle Set: Color',
      unitPrice: 150,
      price: 0,
    },
    {
      id: 13,
      count: 0,
      label: 'Blinds (5 ft., 6 ft. & 9 ft. doors)W/Standard Height',
      unitPrice: 995,
      price: 0,
    },
    {
      id: 14,
      count: 0,
      label: 'SolarZone Elite Upgrade',
      unitPrice: 199,
      price: 0,
    },
    {
      id: 15,
      count: 0,
      label: 'Install Labor',
      unitPrice: 1,
      price: 0,
    },
  ],

  roundUpTableData: [
    {
      id: 1,
      count: 0,
      label: 'St. Jude Children’s Research Hospital',
      unitPrice: 1,
      price: 0,
    },
    {
      id: 2,
      count: 0,
      label: 'Veterans Airlift Command',
      unitPrice: 1,
      price: 0,
    },
  ],

  financingTable: [
    {
      id: 1,
      label:
        'Site set-up, Delivery, and Disposal fee (1-5 = $75, 6-10 = $150, 11+ = $200)',
      price: 200,
    },
    {
      id: 2,
      label: 'Measure Fee',
      price: 75,
    },
    {
      id: 3,
      label: 'Ck',
      price: '',
    },
    {
      id: 4,
      label: 'Custom Order Deposit 50%',
      price: 0,
    },
    {
      id: 5,
      label: 'Balance to be Paid to Installer',
      price: 0,
    },
    {
      id: 6,
      label: 'Amount Financed',
      price: 0,
    },
  ],

  salesmanowner: [
    { id: 1, label: 'Date' },
    { id: 2, label: 'Salesman' },
    { id: 3, label: 'Date' },
    { id: 4, label: 'Owner' },
    { id: 5, label: 'Date' },
    { id: 6, label: 'E-mail' },
  ],

  page2salesperson: [{ id: 1, label: 'Salesperson' }],

  jobContact: {
    job: 'Job',
    phone: 'phone',
    address: 'address',
    inside: 'inside',
    outside: 'outside',
  },

  room: [
    {
      id: 1,
      room: 'BATH',
      style: 'DH',
      grids: 'grids',
      le: 'le',
      size: 'size',
      mull: 'mull',
      note: 'windowNote',
    },
  ],

  type: {
    wood: 1,
    album: 0,
    steel: 1,
    vinyl: 1,
    brick: 1,
    frame: 0,
    siding: 0,
    stucco: 0,
    business: 0,
    rental: 0,
    empty: 0,
    own: 0,
  },
};
