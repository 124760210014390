import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styled from '@emotion/styled';

import { useSelector, useDispatch } from 'react-redux';
import { setUserData } from '../../store/slices/userSlice';
import { useAuthDataContext } from '../AuthProvider';
import { login } from '../../utils/authApi';
import { ToastContainer, toast } from 'react-toastify';

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authData, onLogin, isdemo } = useAuthDataContext();
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Provide a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (e) => {
      const res = login(e)
        .then(({ data }) => {
          toast.success('Login successed!');
          dispatch(
            setUserData({ name: data.user.name, email: data.user.email })
          );
          onLogin(data.user);
          localStorage.setItem('token', data.token);
        })
        .catch((err) => {
          toast.error('Login Failed');
          formik.setSubmitting(false);
        });
    },
  });
  useEffect(() => {
    formik.setSubmitting(false);
  }, []);

  useEffect(() => {
    // if (authData.email) navigate('/documentation');
    // if (isdemo) navigate('/documentation');
    // else navigate('/');
  }, [authData]);

  const [showPassword, setShowPassword] = useState(false);

  const HeadingStyle = styled(Box)({
    textAlign: 'center',
  });

  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.6, ease: easing },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: easing,
      },
    },
  };
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return !authData.email ? (
    <FormikProvider value={formik} style={{ marginTop: '-500px' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <HeadingStyle>
        <Typography
          variant="h3"
          sx={{ color: 'text.secondary', pb: 3, textAlign: 'left' }}
        >
          Sigin
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: 'text.secondary',
            mt: 4,
            mb: 3,
            textAlign: 'left',
            fontSize: 'medium',
          }}
        >
          Please login with your organization credentials
        </Typography>
      </HeadingStyle>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email Address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box initial={{ opacity: 0, y: 20 }} animate={animate}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    {...getFieldProps('remember')}
                    checked={values.remember}
                  />
                }
                label="Remember me"
              />
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2, mx: 2 }}
            >
              <FormControlLabel
                control={
                  <a href="/forgot-password" />
                }
                label={<a href="/forgot-password">Forgot password?</a>}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isSubmitting ? 'loading...' : 'Login'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
      <Typography
        {...fadeInUp}
        variant="body2"
        align="center"
        sx={{ mt: 3 }}
      ></Typography>
    </FormikProvider>
  ) : (
    <>
      <Typography variant="h5" sx={{ color: 'text.secondary', pb: 3, mt: -25 }}>
        {`Welcome, ${authData.name}!`}
      </Typography>
    </>
  );
};

export default LoginForm;
