import * as React from 'react';
import DraggableList from './DraggableList';
import { FormControl, InputLabel, Input, FormHelperText } from '@mui/material';
import { useAuthDataContext } from '../AuthProvider';
import { getUsersSettingInfo, saveUsersSettingInfo } from '../../utils/authApi';
import { Button } from '@mui/material';
import { updateSalesInfo } from '../../store/slices/salesInfoSlice';
import { useSelector, useDispatch } from 'react-redux';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Setting = () => {
  const salesInfoData = useSelector((state) => state.salesInfo.data);
  const dispatch = useDispatch();

  const onChangeTextArea = (e) => {
    const newData = {
      ...data,
      settingInfo: data.settingInfo.map((d) =>
        d.id == e.target.name ? { ...d, value: e.target.value } : d
      ),
    };
    setData(newData);
    // //console.log(e.target.name, e.target.value);
  };

  const onChangeRoleArea = (e) => {
    const newData = {
      ...data,
      settingInfo: data.settingInfo.map((d) =>
        d.id == e.target.name ? { ...d, role: e.target.value } : d
      ),
    };
    // setData(newData);
    // //console.log(e.target.name, e.target.value);
  };

  const { authData } = useAuthDataContext();

  const [data, setData] = React.useState({
    name: '',
    email: '',
    company: '',
    wwStoreNumber: '',
    wwLocation: '',
    wwSalesRep: '',
    settingInfo: [],
    signature: '',
    emailTemplate: ''
  });

  // React.useEffect(() => {
  //   if(salesInfoData) {
  //     setData(d => ({...d, wwStoreNumber: salesInfoData.wwOrder, wwLocation: salesInfoData.salesLocation, wwSalesRep: salesInfoData.repNumber}))
  //   }
  // }, [salesInfoData]);
  
  React.useEffect(() => {
    async function fetchData(authData) {
      const settingInfoData = await getUsersSettingInfo({
        email: authData.email,
      });
      const info = JSON.parse(settingInfoData?.data[0]?.setting_info || '{}');
      //console.log(authData, info.settingInfo.map(s => s.value));
      setData((data) => ({
        ...data,
        name: authData.name,
        email: authData.email,
        settingInfo: info.settingInfo,
        wwStoreNumber: info.wwStoreNumber,
        wwLocation: info.wwLocation,
        wwSalesRep: info.wwSalesRep,
        signature: info.signature || `<b>${authData.name || ''}<br />Window World</b><br />${
          authData.email || ''
        }<br />${authData.phone || ''}<br /><br />` ,
        emailTemplate: info.emailTemplate || info.settingInfo.map(s => s.value).join('<br/><br/>')
      }));
    }

    fetchData(authData);
  }, [authData]);

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;
    const newItems = reorder(data.settingInfo, source.index, destination.index);
    setData({ ...data, settingInfo: newItems });
    onSave();
  };

  const onChange = (e) => {
    //console.log(e.target.name, e.target.value);
    setData((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const onCancel = (id) => {
    const newData = {
      ...data,
      settingInfo: data.settingInfo.filter((d) => d.id !== id),
    };
    setData(newData);
  };

  const onAdd = (e) => {
    //console.log(data.settingInfo);
    setData((data) => ({
      ...data,
      settingInfo: [
        ...data.settingInfo,
        {
          id: `${data.settingInfo.length + 1}`,
          role: 'template',
          value: '',
        },
      ],
    }));
  };

  const onSave = () => {
    saveUsersSettingInfo({
      email: authData.email,
      settingInfo: JSON.stringify(data),
    });
    dispatch(updateSalesInfo({ id: 'settingInfo', value: data }));
    // toast.success('The setting is saved.');
    alert('The setting is saved.');
  };

  return (
    <div className="setting">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <h3>Setting</h3>
      <div className="form-input">
        <div className="label">Name:</div>
        <FormControl error={data.name == ''} variant="standard">
          <Input value={data.name} name="name" onChange={onChange} />
        </FormControl>
      </div>

      <div className="form-input">
        <div className="label">Email:</div>
        <FormControl error={data.email == ''} variant="standard">
          <Input value={data.email} name="email" onChange={onChange} />
        </FormControl>
        <div
          style={{ color: 'lightgreen', margin: 'auto 4px', fontSize: '12px' }}
        >
          <b>VERIFIED✓</b>
        </div>
      </div>

      <div className="form-input">
        <div className="label">Password:</div>
        <FormControl variant="standard">
          <Input value="**************" disabled />
        </FormControl>
      </div>

      <div className="form-input">
        <div className="label">Company:</div>
        {data.company}
      </div>

      <div className="form-input">
        <div className="label" style={{ width: '70%' }}>
          WW Store Number #:
        </div>
        <FormControl error={data.wwStoreNumber == ''} variant="standard">
          <Input
            value={data.wwStoreNumber}
            name="wwStoreNumber"
            onChange={onChange}
          />
        </FormControl>
      </div>

      <div className="form-input">
        <div className="label" style={{ width: '40%' }}>
          WW Location:
        </div>
        <FormControl error={data.wwLocation == ''} variant="standard">
          <Input
            value={data.wwLocation}
            name="wwLocation"
            onChange={onChange}
          />
        </FormControl>
      </div>

      <div className="form-input">
        <div className="label" style={{ width: '40%' }}>
          WW SalesRep:
        </div>
        <FormControl error={data.wwSalesRep == ''} variant="standard">
          <Input
            value={data.wwSalesRep}
            name="wwSalesRep"
            onChange={onChange}
          />
        </FormControl>
      </div>

      <div className="devider"></div>

      <h4 style={{ marginTop: 0 }}>Email Templates:</h4>
      <div style={{ width: '100%' }}>
        {data.settingInfo.length ? (
          <DraggableList
            items={data.settingInfo || []}
            onDragEnd={onDragEnd}
            onChangeTextArea={onChangeTextArea}
            onChangeRoleArea={onChangeRoleArea}
            onCancel={onCancel}
          />
        ) : null}
      </div>

      <button className="add-btn" onClick={() => onAdd()}>
        (+ ADD)
      </button>
      <br/>
      <h4 style={{ marginTop: 0 }}>Email Templates:</h4>
      <div className="emailtemplate" style={{ width: 'calc(100%)' }}>
        <DefaultEditor
          value={data.emailTemplate}
          onChange={(e) => setData((data) => ({ ...data, emailTemplate: e.target.value }))}
        />
      </div>
      <br/>
      <h4 style={{ marginTop: 0 }}>Signature Templates:</h4>
      <div style={{ width: 'calc(100%)' }}>
        <DefaultEditor
          value={data.signature}
          onChange={(e) => setData((data) => ({ ...data, signature: e.target.value }))}
        />
      </div>
      <br/>
      <br/>
      <Button
        variant="contained"
        size="large"
        sx={{ width: '30%',  margin:'auto'}}
        onClick={() => onSave()}
      >
        Save
      </Button>
    </div>
  );
};

export default Setting;
