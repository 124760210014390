import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Input from '../Input';
import DropDownWrapper from '../DropDownWrapper';
import Signature from '../Signature';
import { numberWithCommas, dollarNumberWithCommas } from '../../utils/globals';
import { updateValue } from '../../store/slices/creditSlice';
import { updateValue as calculateUpdateValue } from '../../store/slices/calculateSlice';

import './style.css';

let months = [];
let years = [];

for (let i = 0; i < 12; i++) {
  months.push(i + 1);
}

const currentYear = new Date().getFullYear();
for (let i = 0; i < 10; i++) {
  years.push(currentYear + i);
}

const CreditCardForm = () => {
  const storeData = useSelector((state) => state.credit.data);
  const viewMode = useSelector((state) => state.option.data.viewMode);
  const signStatus = useSelector((state) => state.option.data.signStatus);
  const calculateData = useSelector((state) => state.calculate.data);
  const dispatch = useDispatch();

  const canEdit = [
    'cc_cash',
    'cc_check1',
    'cc_financing',
    'cc_plan',
    'cc_check2',
    'cc_amount_financed',
    'cc_credit_card',
    'cc_visa',
    'cc_mc',
    'cc_amex',
    'cc_dicover',
    'cc_cardholder_name',
    'cc_card_number',
    'expDate',
    'expMonth',
    'cc_cvv_code',
  ];
  const handleChange = (value, { formId }) => {
    if (canEdit.indexOf(formId) < 0 && viewMode !== 'homepage') return;
    dispatch(updateValue({ id: formId, value: value }));
    if(formId == 'cc_amount_financed')
      dispatch(calculateUpdateValue({ id: 'calculateInput6', count: value }));
    if(formId == 'cc_deposit')
      dispatch(calculateUpdateValue({ id: 'cc_deposit', count: value }));
    if(formId == 'cc_balance')
      dispatch(calculateUpdateValue({ id: 'cc_balance', count: value }));
  };

  const handleSign = (value) => {
    dispatch(updateValue({ id: 'signature', value: value }));
  };
  return (
    <div>
      <div className="cc_container" style={{ marginBottom: '350px' }}>
        <table className="cc_table">
          <thead>
            <tr>
              <th colSpan={4} className="cc_thead">
                <img src="/images/gray4.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>
                  Window World - Payment Form
                </p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CUSTOMER NAME</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_name"
                  style={{ textAlign: 'left', width: '70%' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_name']}
                  readOnlyMode={viewMode !== 'homepage'}
                />
              </td>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>DEPOSIT</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_deposit"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={`${numberWithCommas(storeData['cc_deposit'] || Math.round((Number(calculateData['contractsubtotal']) + Number(calculateData['calculateInput1']) + Number(calculateData['calculateInput2']))/2))}`}
                  readOnlyMode={viewMode !== 'homepage'}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>PHONE</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_phone"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_phone']}
                  readOnlyMode={viewMode !== 'homepage'}
                />
              </td>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>BALANCE</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_balance"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={dollarNumberWithCommas(storeData['cc_balance'])}
                  readOnlyMode={viewMode !== 'homepage'}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>ADDRESS</p>
              </td>
              <td colSpan={3}>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_address"
                  style={{ textAlign: 'left', width: '70%' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_address']}
                  readOnlyMode={viewMode !== 'homepage'}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <table className="cc_table">
          <thead>
            <tr>
              <th colSpan={4} className="cc_thead">
                <img src="/images/gray4.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>Payment Method</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CASH</p>
              </td>
              <td colSpan={3}>
                <input
                  type="checkbox"
                  id="cc_cash"
                  onChange={(e) =>
                    handleChange(e.target.checked, { formId: 'cc_cash' })
                  }
                  checked={storeData['cc_cash']}
                  style={{ width: 20, height: 20 }}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CHECK</p>
              </td>
              <td>
                <input
                  type="checkbox"
                  id="cc_check1"
                  onChange={(e) =>
                    handleChange(e.target.checked, { formId: 'cc_check1' })
                  }
                  checked={storeData['cc_check1']}
                  style={{ width: 20, height: 20 }}
                />
              </td>
              <td
                colSpan={2}
                className="cc_tdtitle"
                style={{ textAlign: 'left' }}
              >
                Check#
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_check2"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_check2']}
                  readOnlyMode={false}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>FINANCING</p>
              </td>
              <td>
                <input
                  type="checkbox"
                  id="cc_financing"
                  onChange={(e) =>
                    handleChange(e.target.checked, { formId: 'cc_financing' })
                  }
                  checked={storeData['cc_financing']}
                  style={{ width: 20, height: 20, marginRight: '10%' }}
                />
                PLAN#
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_plan"
                  style={{ textAlign: 'left', width: '50%' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_plan']}
                  readOnlyMode={false}
                />
              </td>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>AMOUNT FINANCED</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_amount_financed"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={dollarNumberWithCommas(storeData['cc_amount_financed'] || 0)}
                  readOnlyMode={false}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CREDIT CARD</p>
              </td>
              <td colSpan={3}>
                <span style={{ marginLeft: '5%', marginRight: '5%' }}>
                  <input
                    type="checkbox"
                    id="cc_visa"
                    onChange={(e) =>
                      handleChange(e.target.checked, { formId: 'cc_visa' })
                    }
                    checked={storeData['cc_visa']}
                  />
                  <label htmlFor="cc_visa">Visa</label>
                </span>
                <span style={{ marginLeft: '5%', marginRight: '5%' }}>
                  <input
                    type="checkbox"
                    id="cc_mc"
                    onChange={(e) =>
                      handleChange(e.target.checked, { formId: 'cc_mc' })
                    }
                    checked={storeData['cc_mc']}
                  />
                  <label htmlFor="cc_mc">MC</label>
                </span>
                <span style={{ marginLeft: '5%', marginRight: '5%' }}>
                  <input
                    type="checkbox"
                    id="cc_amex"
                    onChange={(e) =>
                      handleChange(e.target.checked, { formId: 'cc_amex' })
                    }
                    checked={storeData['cc_amex']}
                  />
                  <label htmlFor="cc_amex">AMEX</label>
                </span>

                <span style={{ marginLeft: '5%', marginRight: '5%' }}>
                  <input
                    type="checkbox"
                    id="cc_dicover"
                    onChange={(e) =>
                      handleChange(e.target.checked, { formId: 'cc_dicover' })
                    }
                    checked={storeData['cc_dicover']}
                  />
                  <label htmlFor="cc_dicover">DISCOVER</label>
                </span>
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CARDHOLDER NAME</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_cardholder_name"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_cardholder_name']}
                  readOnlyMode={false}
                />
              </td>
              <td
                rowSpan={4}
                colSpan={2}
                className="cc_tdtitle"
                style={{ textAlign: 'center' }}
              >
                I authorize the above card to be run for the amount as well as
                the balance. If for some unforseen reason the entire job doesn't
                get completed, I authorize the work that did get completed to be
                run that day. I will then pay the remainder, after the rest of
                the work is completed. This ensures that our installers get paid
                for the work they have gotten completed thus far.
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CARD NUMBER</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_card_number"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_card_number']}
                  readOnlyMode={false}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>EXP. DATE</p>
              </td>
              <td>
                <DropDownWrapper
                  style={{
                    display: 'inline-block',
                    width: '100px',
                    textAlign: 'right',
                  }}
                  isInputEnable={true}
                  value={storeData['expDate']}
                >
                  <select
                    className="date-select"
                    onChange={(e) => {
                      handleChange(e.target.value, { formId: 'expDate' });
                    }}
                    value={storeData['expDate']}
                  >
                    <option value=" "> </option>
                    {months.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </select>
                </DropDownWrapper>
                /
                <DropDownWrapper
                  style={{
                    display: 'inline-block',
                    width: '100px',
                    textAlign: 'left',
                  }}
                  isInputEnable={true}
                  value={storeData['expMonth']}
                >
                  <select
                    className="date-select"
                    onChange={(e) => {
                      handleChange(e.target.value, { formId: 'expMonth' });
                    }}
                    value={storeData['expMonth']}
                  >
                    <option value=" "> </option>
                    {years.map((value, index) => (
                      <option value={value} key={index}>
                        {value}
                      </option>
                    ))}
                  </select>
                </DropDownWrapper>
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>CVV CODE</p>
              </td>
              <td>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="cc_cvv_code"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={storeData['cc_cvv_code']}
                  readOnlyMode={false}
                />
              </td>
            </tr>
            <tr>
              <td className="cc_tdtitle">
                <img src="/images/gray3.png" alt="gray" />
                <p style={{ marginTop: '-40px' }}>Customer Signature</p>
              </td>
              <td>
                <Signature
                  width={200}
                  height={28}
                  style={{ ...storeData.signature.style, marginTop: '-5px' }}
                  signId="signature"
                  updateSign={handleSign}
                  imgInfo={storeData.signature.value}
                  signStatus={signStatus}
                  viewMode={viewMode}
                />
              </td>
              <td colSpan={2} className="cc_tdtitle">
                <label htmlFor="signDate">Date: </label>
                <Input
                  addClass="medium-input"
                  type={'text'}
                  inputId="signDate"
                  style={{ textAlign: 'left' }}
                  updateData={handleChange}
                  inputVal={storeData['signDate']}
                  readOnlyMode={viewMode !== 'homepage'}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CreditCardForm;
