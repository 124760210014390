import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import styled from '@emotion/styled';
import CreateContract from '../../components/CreateContract';
import { useAuthDataContext } from '../../components/AuthProvider';
import { useNavigate } from 'react-router-dom';
//////////////////////////////////
const RootStyle = styled('div')({
  height: '100vh',
  display: 'grid',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const Auth = ({ isLogin }) => {
  const navigate = useNavigate();
  const { authData, isdemo } = useAuthDataContext();
  useEffect(() => {
    if (isdemo || !authData.email) {
      navigate('/');
    }
  }, [authData, isdemo]);
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <CreateContract />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Auth;
