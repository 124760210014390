import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { ChevronLeft, Menu } from '@mui/icons-material';
import { styles } from './material-ui.styles';
import { useAuthDataContext } from '../components/AuthProvider';

const useStyles = makeStyles(styles);

const Header = ({ isOpened, setIsOpened }) => {
  const classes = useStyles();
  const { authData, isdemo } = useAuthDataContext();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <img
          src="https://measureform.com/images/logo.png"
          style={{
            width: '200px',
            height: '45px',
          }}
          alt="logo"
        />

        {authData.email && (
          <IconButton
            color="inherit"
            onClick={() => setIsOpened(!isOpened)}
            className={classes.icon}
          >
            {!isdemo && (isOpened ? <ChevronLeft /> : <Menu />)}
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
