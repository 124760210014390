import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import styled from '@emotion/styled';
import ResetPasswordForm from '../../components/ForgotPasswordForm/reset';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthDataContext } from '../../components/AuthProvider';

//////////////////////////////////
const RootStyle = styled('div')({
  height: '100vh',
  display: 'grid',
  placeItems: 'center',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const Auth = ({ isLogin }) => {
  const navigate = useNavigate();
  
  return (
    <RootStyle style={{ marginTop: '-100px' }}>
      <Container maxWidth="sm">
        <ContentStyle>
          <ResetPasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Auth;
