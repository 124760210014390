import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  updateWindowTable,
  updateTypeTable,
  updateMainTable,
} from '../../../store/slices/measuresheetSlice';
import { updateMainTable as updateMainTableForWindowOrder } from '../../../store/slices/windoworderSlice';
import { updateValue as updateMiscellenous } from '../../../store/slices/miscellenousSlice';
import { updateValue as updateWindowoption } from '../../../store/slices/windowoptionSlice';

import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
  cutbacks,
  initDataOfWindowOrder,
  gridStyle,
  texture,
  buildingType,
} from '../../../constants/variables';

import { fractionCalculator } from '../../../utils/globals';

let data;

const dataForWindowOrder = {
  mainTable: {},
};

const MeasureSheet = () => {
  const navigate = useNavigate();
  const measuresheetData = useSelector((state) => state.measuresheet.data);
  data = { ...measuresheetData };
  const windowOrderData = useSelector((state) => state.windoworder.data);

  dataForWindowOrder.mainTable = { ...windowOrderData.mainTable };

  const dispatch = useDispatch();

  const handleChangeWindowOption = (e) => {
    // data.windowTable[e.target.id] = e.target.value;
    data.windowTable = { ...data.windowTable, [e.target.name]: e.target.value };
    data.windowTable.cutbacks = cutbacks[data.windowTable.cutbacksStr];
    dispatch(updateWindowTable(data.windowTable));

    // calculateOrderWidthHeight();

    // dispatch(updateMainTable(data.mainTable));
    // processWindowOrderData();
  };

  const handleChangeTypeTable = (e) => {
    data.typeTable = { ...data.typeTable, [e.target.name]: e.target.value };
    dispatch(updateTypeTable({ ...data.typeTable }));
    processWindowOrderData();
  };

  const processWindowOrderData = () => {
    Object.keys(dataForWindowOrder.mainTable).forEach((key) => {
      dataForWindowOrder.mainTable[key] = { ...initDataOfWindowOrder };
    });

    Object.values(data.mainTable).forEach((ele) => {
      if (ele.categoryNum >= 0) {
        console.log('???=>ele.categoryNum:', ele.categoryNum);
        dataForWindowOrder.mainTable[ele.categoryNum] = {
          ...dataForWindowOrder.mainTable[ele.categoryNum],
          categoryNum: ele.categoryNum,
          qty: parseInt(dataForWindowOrder.mainTable[ele.categoryNum].qty + 1),
          intColor: ele.intColor,
          extColor: ele.extColor,
          type: ele.style,
          szWidth: ele.orderWidth,
          szHeight: ele.orderHeight,
          energy: ele.energy,
          obsc: ele.obsc,
          temp: ele.temp,
          pattern: ele.grids,
          foam: ele.foam,
          grids: ele.grids != '' ? true : false,
        };
      }
    });

    dispatch(updateMainTableForWindowOrder(dataForWindowOrder.mainTable));
  };
  const lastrow = Object.entries(data.mainTable).find(
    ([k, v]) => v.room === '' && v.style === '' && v.notes === ''
  )[0];

  return (
    <Box sx={{ minWidth: 120, marginBottom: 5 }}>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">
          TYPE OF WINDOW TEAROUTS
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="tearouts"
          value={measuresheetData.windowTable.tearouts}
          label="TYPE OF WINDOW TEAROUTS"
          onChange={(e) => handleChangeWindowOption(e)}
        >
          <MenuItem value={'WOOD'}>WOOD</MenuItem>
          <MenuItem value={'ALUM'}>ALUM</MenuItem>
          <MenuItem value={'VINYL'}>VINYL</MenuItem>
          <MenuItem value={'STEEL'}>STEEL</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">
          TYPE OF WINDOW POCKET
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="pockets"
          value={measuresheetData.windowTable.pockets}
          label="TYPE OF WINDOW POCKET"
          onChange={(e) => handleChangeWindowOption(e)}
        >
          <MenuItem value={'WOOD'}>WOOD</MenuItem>
          <MenuItem value={'PLASTER'}>PLASTER</MenuItem>
          <MenuItem value={'DRYWALL'}>DRYWALL</MenuItem>
          <MenuItem value={'PLASTER'}>CONCRETE</MenuItem>
          <MenuItem value={'DRYWALL'}>OTHER</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">WINDOW CUTBACKS</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="cutbacksStr"
          value={measuresheetData.windowTable.cutbacksStr}
          label="WINDOW CUTBACKS"
          onChange={(e) => handleChangeWindowOption(e)}
        >
          {Object.keys(cutbacks).map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">TYPE OF BUILDING</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="typeOfBuilding"
          value={measuresheetData.windowTable.typeOfBuilding}
          label="TYPE OF BUILDING"
          onChange={(e) => handleChangeWindowOption(e)}
        >
          {buildingType &&
            buildingType.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">GRID STYLE</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="gridStyle"
          value={measuresheetData.typeTable.gridStyle}
          label="GRID STYLE"
          onChange={(e) => handleChangeTypeTable(e)}
        >
          {gridStyle.normalType.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">CAPPING STYLE</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="capping"
          value={measuresheetData.typeTable.capping}
          label="CAPPING STYLE"
          onChange={(e) => handleChangeTypeTable(e)}
        >
          <MenuItem value="BRICKMOLD">BRICKMOLD</MenuItem>
          <MenuItem value="1x4">1x4</MenuItem>
          <MenuItem value="1x6">1x6</MenuItem>
          <MenuItem value="OTHER">OTHER</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="trimColor"
          value={measuresheetData.typeTable.trimColor}
          label="TRIM COLOR"
          onChange={(e) => handleChangeTypeTable(e)}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">TEXTURE</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="texture"
          value={measuresheetData.typeTable.texture}
          label="TEXTURE"
          onChange={(e) => handleChangeTypeTable(e)}
        >
          {texture.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ marginBottom: 6 }}>
        <Button
          variant="contained"
          onClick={() => {
            navigate(
              `/new/customer/:customer/createcontract/measure/${lastrow}`
            );

            dispatch(
              updateWindowoption({
                id: 'windowOptionInput14',
                count: measuresheetData.typeTable.gridStyle,
              })
            );
            dispatch(
              updateMiscellenous({
                id: 'miscellenousInput40',
                count: measuresheetData.typeTable.trimColor,
              })
            );
          }}
        >
          Next
        </Button>
      </FormControl>
    </Box>
  );
};

export default MeasureSheet;
