import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useAuthDataContext } from '../components/AuthProvider';

import { styles } from './material-ui.styles';
import Header from './Header';
import Footer from './Footer';

const useStyles = makeStyles(styles);

const Layout = ({ children }) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);
  const { authData, isdemo, onLogout } = useAuthDataContext();

  return (
    <div className={classes.root}>
      <Header isOpened={isOpened} setIsOpened={setIsOpened} />
      <Toolbar />
      <div className={classes.container}>
        <main className={classes.main}>{children}</main>
        {!isdemo ? (
          <Drawer
            variant="permanent"
            anchor="right"
            classes={{
              paper: clsx(classes.drawer, {
                [classes.closed]: !isOpened,
                [classes.opened]: isOpened,
              }),
            }}
            PaperProps={{
              sx: {
                transition: 'width .4s',
                zIndex: 1,
              },
            }}
          >
            <ButtonGroup
              orientation="vertical"
              aria-label="vertical contained button group"
              variant="text"
              className={classes.buttongroup}
            >
              <Typography
                variant="p"
                className={classes.title}
                sx={{
                  fontSize: 'small',
                  backgroundColor: '#007aff',
                  color: 'white',
                  padding: '10px 1px',
                }}
              >
                {`Measure Form${
                  authData.email
                    ? ` -Window World Kentuckiana #348 (logged in as: ${authData.name}) `
                    : ''
                }`}
              </Typography>
              {authData.isadmin ? (
                <>
                  <Button key="admin" onClick={() => setIsOpened(false)}>
                    <Link
                      component={RouterLink}
                      to="/admin"
                      style={{ textDecoration: 'none', color: 'blue' }}
                    >
                      <b>Admin</b>
                    </Link>
                  </Button>
                  <Button key="setting" onClick={() => setIsOpened(false)}>
                    <Link
                      component={RouterLink}
                      to="/setting"
                      style={{ textDecoration: 'none', color: 'blue' }}
                    >
                      <b>SETTING</b>
                    </Link>
                  </Button>
                </>
              ) : (
                <Button key="setting" onClick={() => setIsOpened(false)}>
                  <Link
                    component={RouterLink}
                    to="/setting"
                    style={{ textDecoration: 'none', color: 'blue' }}
                  >
                    <b>SETTING</b>
                  </Link>
                </Button>
              )}
              {/*  */}
              <Button key="document" onClick={() => setIsOpened(false)}>
                <Link
                  component={RouterLink}
                  to="/"
                  style={{
                    textDecoration: 'none',
                    color: 'blue',
                  }}
                  onClick={() => {
                    localStorage.clear();
                    onLogout();
                  }}
                >
                  <b>LOGOUT</b>
                </Link>
              </Button>
            </ButtonGroup>
          </Drawer>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
