import React, { useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useAuthDataContext } from '../../components/AuthProvider';
import { useNavigate } from 'react-router-dom';
//////////////////////////////////
const RootStyle = styled('div')({
  height: '80vh',
  display: 'grid',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const Landing = () => {
  const navigate = useNavigate();
  const { authData, isdemo } = useAuthDataContext();
  useEffect(() => {
    if (isdemo || !authData.email) {
      navigate('/');
    }
  }, [authData, isdemo]);
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h3" sx={{ color: 'text.secondary', pb: 3 }}>
            {authData.name || 'Window World'}
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Landing;
