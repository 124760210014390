import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import DrawBox from '../../DrawBox';
import Modal from 'react-modal';
import { updateDrawingDataFunc } from '../../../store/slices/measuresheetSlice';
import { updateValue } from '../../../store/slices/miscellenousSlice';

import Checkbox from '../../Checkbox';

import {
  updateWindowTable,
  updateTypeTable,
  updateMainTable,
} from '../../../store/slices/measuresheetSlice';
import { updateMainTable as updateMainTableForWindowOrder } from '../../../store/slices/windoworderSlice';

import {
  initDataOfMeasureSheet,
  nonComparisonElementArray,
  typeOfCheckBox,
  interiorColor,
  exteriorColor,
  roomItems,
  roomStyle,
  energy,
  obsc,
  temp,
  cutbacks,
  initDataOfWindowOrder,
  gridStyle,
  texture,
  buildingType,
} from '../../../constants/variables';

import { fractionCalculator } from '../../../utils/globals';
import './style.css';

const tableHeaderLine = [
  'No.',
  'ROOM',
  'STYLE',
  'R.O.WIDTH',
  'R.O.HEIGHT',
  'ORDER WIDTH',
  'ORDER HEIGHT',
  'GRIDS/BLINDS',
  'INT COLOR',
  'EXT COLOR',
  'FOAM',
  'TEMP',
  'OBSC',
  'ENERGY',
  'MULL CUTS',
  'NOTES',
];
// let data = {
//   windowTable: {
//     tearouts: 'WOOD',
//     pockets: 'WOOD',
//     cutbacks: { w: '-3/8' },
//   },
//   typeTable: { grid: 'NO GRIDS', capping: 'BRICKMOLD' },
//   mainTable: {},
// };

let data;

const dataForWindowOrder = {
  mainTable: {},
};

const MeasureSheet = ({ page }) => {
  const salesInfo = useSelector((state) => state.salesInfo.data);
  const measuresheetData = useSelector((state) => state.measuresheet.data);
  data = { ...measuresheetData };
  const windowOrderData = useSelector((state) => state.windoworder.data);

  const viewMode = useSelector((state) => state.option.data.viewMode);

  dataForWindowOrder.mainTable = { ...windowOrderData.mainTable };

  const [openTableModal, setOpenTableModal] = useState(false);
  const [tempObj, setTempObj] = useState({});
  const [selectedRow, setSelectedRow] = useState(0);
  const [copyTemp, setCopyTemp] = useState(null);
  const dispatch = useDispatch();

  const calculateOrderWidthHeight = () => {
    Object.values(data.mainTable).forEach((ele, index) => {
      data.mainTable[index] = {
        ...ele,
        orderWidth:
          ele.style === 'SPD'
            ? ele.orderWidth
            : ele.roWidth
            ? ele.style === 'DH2' ? fractionCalculator((parseInt(ele.roWidth)).toString(), '+', data.windowTable.cutbacks.w) 
            : ele.style === 'DH3' ? fractionCalculator((parseInt(ele.roWidth)).toString(), '+', data.windowTable.cutbacks.w)
            : fractionCalculator(ele.roWidth, '+', data.windowTable.cutbacks.w)
            : '',
        orderHeight:
          ele.style === 'SPD'
            ? ele.orderHeight
            : ele.roHeight
            ? fractionCalculator(ele.roHeight, '+', data.windowTable.cutbacks.h)
            : '',
      };
    });
  };

  const handleSign = (value) => {
    dispatch(updateDrawingDataFunc(value));
  };

  const handleChangeWindowOption = (e) => {
    // data.windowTable[e.target.id] = e.target.value;
    data.windowTable = { ...data.windowTable, [e.target.id]: e.target.value };
    data.windowTable.cutbacks = cutbacks[data.windowTable.cutbacksStr];
    dispatch(updateWindowTable(data.windowTable));

    calculateOrderWidthHeight();

    dispatch(updateMainTable(data.mainTable));
    processWindowOrderData();
  };

  const handleChangeTypeTable = (e) => {
    // data.typeTable[e.target.id] = e.target.value;
    data.typeTable = { ...data.typeTable, [e.target.id]: e.target.value };
    dispatch(updateTypeTable({ ...data.typeTable }));

    if(e.target.id === 'trimColor')
      dispatch(updateValue({ id: 'miscellenousInput40', count: e.target.value }));
    
    // console.log("???=>type table data:", e.target, measuresheetData.typeTable)

    processWindowOrderData();
  };

  const handleChangeInput = (e) => {
    switch (e.target.id) {
      case 'style':
        setTempObj({
          ...tempObj,
          [e.target.id]: e.target.value,
          orderWidth:
            e.target.value === 'SPD'
              ? tempObj.roWidth
              : tempObj.roWidth
              ? e.target.value === 'DH2' ? fractionCalculator((parseInt(tempObj.roWidth)).toString(), '+',measuresheetData.windowTable.cutbacks.w) 
              : e.target.value === 'DH3' ? fractionCalculator((parseInt(tempObj.roWidth)).toString(), '+',measuresheetData.windowTable.cutbacks.w)
              : e.target.value === 'CAS2' ? fractionCalculator((parseInt(tempObj.roWidth)/2).toString(), '+',measuresheetData.windowTable.cutbacks.w) 
              : e.target.value === 'CAS3' ? fractionCalculator((parseInt(tempObj.roWidth)/3).toString(), '+',measuresheetData.windowTable.cutbacks.w)
              : fractionCalculator(
                  tempObj.roWidth,
                  '+',
                  measuresheetData.windowTable.cutbacks.w
                )
              : '',
          orderHeight:
            e.target.value === 'SPD'
              ? tempObj.roHeight
              : tempObj.roHeight
              ? fractionCalculator(
                  tempObj.roHeight,
                  '+',
                  measuresheetData.windowTable.cutbacks.h
                )
              : '',
        });

        dataForWindowOrder.mainTable[0] = {
          ...dataForWindowOrder.mainTable[0],
          szWidth: tempObj.orderWidth,
        };
        dispatch(
          updateMainTableForWindowOrder({
            ...dataForWindowOrder.mainTable,
            0: { ...dataForWindowOrder.mainTable[0] },
          })
        );
        break;
      case 'roWidth':
        setTempObj({
          ...tempObj,
          [e.target.id]: e.target.value,
          orderWidth:
            tempObj.style === 'SPD'
              ? tempObj.orderWidth
              : e.target.value
              ? tempObj.style === 'DH2' ? fractionCalculator((parseInt(e.target.value)).toString(), '+',measuresheetData.windowTable.cutbacks.w) 
              : tempObj.style === 'DH3' ? fractionCalculator((parseInt(e.target.value)).toString(), '+',measuresheetData.windowTable.cutbacks.w)
              : e.target.value === 'CAS2' ? fractionCalculator((parseInt(e.target.value)/2).toString(), '+',measuresheetData.windowTable.cutbacks.w) 
              : e.target.value === 'CAS3' ? fractionCalculator((parseInt(e.target.value)/3).toString(), '+',measuresheetData.windowTable.cutbacks.w)
              : fractionCalculator(
                  e.target.value,
                  '+',
                  measuresheetData.windowTable.cutbacks.w
                )
              : '',
        });

        break;
      case 'roHeight':
        setTempObj({
          ...tempObj,
          [e.target.id]: e.target.value,
          orderHeight:
            tempObj.style === 'SPD'
              ? tempObj.orderHeight
              : e.target.value
              ? fractionCalculator(
                  e.target.value,
                  '+',
                  measuresheetData.windowTable.cutbacks.h
                )
              : '',
        });
        break;
      default:
        setTempObj({
          ...tempObj,
          [e.target.id]: e.target.value,
        });
        break;
    }
  };

  const handleClickTr = (row_id) => {
    setSelectedRow(row_id);
    setTempObj({ ...measuresheetData.mainTable[row_id] });
    setOpenTableModal(viewMode === 'homepage');
  };

  const findLastCategoryNumKindBeforeIndex = (index) => {
    let result = -1;
    for (let i = 0; i < index; i++) {
      //console.log('???=>i-categoryNum:', data.mainTable[i].categoryNum);
      if (data.mainTable[i].categoryNum > result) {
        result = data.mainTable[i].categoryNum;
      }
    }

    //console.log('???=>last category num:', result);
    return result;
  };

  const isOnlyOneCategory = (index) => {
    const length = Object.keys(data.mainTable).length;

    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else {
        if (
          Object.keys(data.mainTable[index]).every((key) => {
            if (nonComparisonElementArray.find((val) => val === key)) {
              return true;
            } else {
              return data.mainTable[i][key] === data.mainTable[index][key];
            }
          })
        )
          return false;
      }
    }
    return true;
  };

  const isOnlyOneCategoryInPreState = (index) => {
    const length = Object.keys(data.mainTable).length;
    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else {
        if (
          Object.keys(measuresheetData.mainTable[index]).every((key) => {
            if (nonComparisonElementArray.find((val) => val === key)) {
              return true;
            } else {
              return (
                measuresheetData.mainTable[i][key] ===
                measuresheetData.mainTable[index][key]
              );
            }
          })
        )
          return false;
      }
    }
    return true;
  };

  const findFirstIdenticalIndex = (index) => {
    const length = Object.keys(data.mainTable).length;
    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else if (
        Object.keys(data.mainTable[index]).every((key) => {
          if (nonComparisonElementArray.find((val) => val === key)) {
            return true;
          } else {
            return data.mainTable[i][key] === data.mainTable[index][key];
          }
        })
      ) {
        //console.log('???=>identical category index:', i);
        return i;
      }
    }
    return -1;
  };

  const findFirstIdenticalIndexBeforeChanging = (index) => {
    if (
      Object.keys(measuresheetData.mainTable[index]).every((key) => {
        if (nonComparisonElementArray.find((val) => val === key)) {
          return true;
        } else {
          return data.mainTable[index][key] === '';
        }
      })
    )
      return -1;
    const length = Object.keys(data.mainTable).length;
    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else {
        if (
          Object.keys(measuresheetData.mainTable[index]).every((key) => {
            if (nonComparisonElementArray.find((val) => val === key)) {
              return true;
            } else {
              return (
                data.mainTable[i][key] ===
                measuresheetData.mainTable[index][key]
              );
            }
          })
        ) {
          //console.log('???=>identical category index:', i);
          return i;
        }
      }
    }
    return -1;
  };

  const estimateCategoryNum = (index) => {
    if (data.mainTable[index].categoryNum === -1)
      data.mainTable[index].categoryNum = 0;

    let lastCategoryNum = findLastCategoryNumKindBeforeIndex(index);

    //console.log('???=>isOnly', isOnlyOneCategory(index));

    if (isOnlyOneCategoryInPreState(index)) {
      if (isOnlyOneCategory(index)) {
        //console.log('???=> pre: only, cur: only');
      } else {
        //console.log('???=> pre: only, cur: NOT only');
        const firstIdenticalIndex = findFirstIdenticalIndex(index);
        //console.log('???=>firstIdenticalIndex:', firstIdenticalIndex);
        data.mainTable[index] = {
          ...data.mainTable[index],
          categoryNum: data.mainTable[firstIdenticalIndex].categoryNum,
        };
        let standardIndex;
        if (firstIdenticalIndex < index) {
          standardIndex = index;
          const length = Object.keys(data.mainTable).length;
          for (let i = 0; i < length; i++) {
            if (
              data.mainTable[i].categoryNum >
              measuresheetData.mainTable[standardIndex].categoryNum
            ) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: data.mainTable[i].categoryNum - 1,
              };
            }
          }
        } else {
          const length = Object.keys(data.mainTable).length;
          for (let i = 0; i < length; i++) {
            if (
              Object.keys(data.mainTable[index]).every((key) => {
                if (nonComparisonElementArray.find((val) => val === key)) {
                  return true;
                } else {
                  return data.mainTable[i][key] === data.mainTable[index][key];
                }
              })
            ) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: measuresheetData.mainTable[index].categoryNum,
              };
            }
          }

          standardIndex = firstIdenticalIndex;
          for (let i = 0; i < length; i++) {
            if (
              data.mainTable[i].categoryNum >
              measuresheetData.mainTable[standardIndex].categoryNum
            ) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: data.mainTable[i].categoryNum - 1,
              };
            }
          }
        }
      }
    } else {
      if (isOnlyOneCategory(index)) {
        //console.log('???=> pre: NOT only, cur: only');
        const length = Object.keys(data.mainTable).length;
        const firstIdenticalIndexBeforeChanging =
          findFirstIdenticalIndexBeforeChanging(index);
        if (firstIdenticalIndexBeforeChanging < index) {
          for (let i = 0; i < length; i++) {
            console.log(
              '???=>i - category num:',
              data.mainTable[i].categoryNum
            );
            if (measuresheetData.mainTable[i].categoryNum > lastCategoryNum) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: data.mainTable[i].categoryNum + 1,
              };
            }
          }
        } else {
          // in case of adding newly
          if (
            measuresheetData.mainTable[index].categoryNum === -1 &&
            !Object.keys(data.mainTable[index]).every((key) => {
              if (nonComparisonElementArray.find((val) => val === key)) {
                return true;
              } else {
                return (
                  data.mainTable[index][key] === initDataOfMeasureSheet[key]
                );
              }
            })
          ) {
            const length = Object.keys(data.mainTable).length;
            for (let i = 0; i < length; i++) {
              if (data.mainTable[i].categoryNum > lastCategoryNum) {
                data.mainTable[i] = {
                  ...data.mainTable[i],
                  categoryNum: data.mainTable[i].categoryNum + 1,
                };
              }
            }

            data.mainTable[index] = {
              ...data.mainTable[index],
              categoryNum: lastCategoryNum + 1,
            };
          } else {
            const standardIndex = findLastCategoryNumKindBeforeIndex(
              firstIdenticalIndexBeforeChanging
            );

            //console.log('???=> standardIndex:', standardIndex);

            const length = Object.keys(data.mainTable).length;
            for (let i = 0; i < length; i++) {
              // in case of init
              if (
                !Object.keys(data.mainTable[index]).every((key) => {
                  if (nonComparisonElementArray.find((val) => val === key)) {
                    return true;
                  } else {
                    return (
                      measuresheetData.mainTable[i][key] ===
                      initDataOfMeasureSheet[key]
                    );
                  }
                })
              ) {
                console.log(`???=>${i}:`, data.mainTable[i]);
                if (
                  Object.keys(data.mainTable[index]).every((key) => {
                    if (nonComparisonElementArray.find((val) => val === key)) {
                      return true;
                    } else {
                      return (
                        data.mainTable[i][key] ===
                        measuresheetData.mainTable[index][key]
                      );
                    }
                  })
                ) {
                  data.mainTable[i] = {
                    ...data.mainTable[i],
                    categoryNum: standardIndex + 1,
                  };
                } else if (data.mainTable[i].categoryNum > standardIndex) {
                  data.mainTable[i] = {
                    ...data.mainTable[i],
                    categoryNum: data.mainTable[i].categoryNum + 1,
                  };
                }
              }
            }
          }
        }
        data.mainTable[index] = {
          ...data.mainTable[index],
          categoryNum: lastCategoryNum + 1,
        };
      } else {
        //console.log('???=> pre: NOT only, cur: NOT only');
        const firstIdenticalIndex = findFirstIdenticalIndex(index);
        //console.log('???=>firstIdenticalIndex:', firstIdenticalIndex);
        data.mainTable[index] = {
          ...data.mainTable[index],
          categoryNum: data.mainTable[firstIdenticalIndex].categoryNum,
        };
      }
    }
  };

  const processWindowOrderData = () => {
    Object.keys(dataForWindowOrder.mainTable).forEach((key) => {
      dataForWindowOrder.mainTable[key] = { ...initDataOfWindowOrder };
    });

    Object.values(data.mainTable).forEach((ele) => {
      if (ele.categoryNum >= 0) {
        //console.log('???=>ele.categoryNum:', ele.categoryNum);
        dataForWindowOrder.mainTable[ele.categoryNum] = {
          ...dataForWindowOrder.mainTable[ele.categoryNum],
          categoryNum: ele.categoryNum,
          qty: parseInt(dataForWindowOrder.mainTable[ele.categoryNum].qty + 1),
          intColor: ele.intColor,
          extColor: ele.extColor,
          type: ele.style,
          szWidth: ele.orderWidth,
          szHeight: ele.orderHeight,
          energy: ele.energy,
          obsc: ele.obsc,
          temp: ele.temp,
          pattern: ele.grids,
          foam: ele.foam,
          grids: ele.grids != '' ? true : false,
          // grids:
          //   gridStyle.shortType[
          //     gridStyle.normalType.findIndex(
          //       (val) => val === data.typeTable.gridStyle
          //     )
          //   ],
        };
      }
    });

    dispatch(updateMainTableForWindowOrder(dataForWindowOrder.mainTable));
  };
  const lastrow = Object.entries(data.mainTable).find(
    ([k, v]) => v.room === '' && v.style === '' && v.notes === ''
  )[0];
  const handleCopy = () => {
    setCopyTemp(data.mainTable[selectedRow]);
    setOpenTableModal(false);
  };
  const handlePaste = () => {
    data.mainTable[selectedRow] = {
      ...copyTemp,
      no: selectedRow + 1,
    };
    estimateCategoryNum(selectedRow);
    dispatch(updateMainTable({ ...data.mainTable }));

    processWindowOrderData();
    setOpenTableModal(false);
    // populateMainTable();
  };

  const handleClear = () => {
    setTempObj({ ...initDataOfMeasureSheet, no: selectedRow + 1 });
  };
  const handleSave = () => {
    data.mainTable[selectedRow] = {
      ...tempObj,
    };
    estimateCategoryNum(selectedRow);
    dispatch(updateMainTable(data.mainTable));

    processWindowOrderData();
    setOpenTableModal(false);
  };

  const handleChangeCheckbox = (value, { formId }) => {
    setTempObj({ ...tempObj, [formId]: value });
  };

  const TableHeader = () => {
    return (
      <thead>
        <tr>
          {tableHeaderLine &&
            tableHeaderLine.map((value, index) => <th key={index}>{value}</th>)}
        </tr>
      </thead>
    );
  };

  const TableBody = () => {
    // const boldElementArray = ['orderWidth', 'orderHeight'];
    const boldElementArray = ['orderWidth', 'orderHeight', 'categoryNum'];
    const checkBoxArray = ['foam'];
    const hiddenElementArray = ['categoryNum'];
    // const hiddenElementArray = [];
    data.mainTable = { ...measuresheetData.mainTable };
    return (
      <tbody>
        {data.mainTable &&
          Object.values(measuresheetData.mainTable).map((ele, row_id) => {
            if (page === 1) {
              if (row_id < 18)
                return (
                  <tr key={row_id} onClick={() => handleClickTr(row_id)}>
                    {Object.keys(ele).map((key, index) =>
                      hiddenElementArray.find((val) => val === key) ? (
                        ''
                      ) : (
                        <td
                          key={index}
                          className={
                            boldElementArray.find((val) => val === key)
                              ? 'bold measure-sheet__big-font'
                              : ''
                          }
                        >
                          {checkBoxArray.find((val) => val === key) ? (
                            <Checkbox
                              checkVal={ele[key]}
                              checkId={key}
                              updateCheck={handleChangeCheckbox}
                              isInputEnable={viewMode === 'homepage'}
                              type={typeOfCheckBox.PatioDoorOrder}
                            />
                          ) : (
                            ele[key]
                          )}
                        </td>
                      )
                    )}
                  </tr>
                );
            } else if (page === 2) {
              if (row_id >= 18)
                return (
                  <tr key={row_id} onClick={() => handleClickTr(row_id)}>
                    {Object.keys(ele).map((key, index) =>
                      hiddenElementArray.find((val) => val === key) ? (
                        ''
                      ) : (
                        <td
                          key={index}
                          className={
                            boldElementArray.find((val) => val === key)
                              ? 'bold measure-sheet__big-font'
                              : ''
                          }
                        >
                          {checkBoxArray.find((val) => val === key) ? (
                            <Checkbox
                              checkVal={ele[key]}
                              checkId={key}
                              updateCheck={handleChangeCheckbox}
                              isInputEnable={viewMode === 'homepage'}
                              type={typeOfCheckBox.PatioDoorOrder}
                            />
                          ) : (
                            ele[key]
                          )}
                        </td>
                      )
                    )}
                  </tr>
                );
            }
          })}
      </tbody>
    );
  };

  return (
    <div className="msh__container">
      {page === 1 ? (
        <div>
          <div className="flex justify-content__space-between width-100">
            <div
              className="flex align-items__end bold"
              style={{ fontSize: '27px' }}
            >
              MEASURE SHEET <div></div>
            </div>
            <div className="flex width-50">
              <div className="width-50">
                <div className="flex margin-top-15px">
                  <div className="right-align width-30">Customer:</div>
                  <div className="border-bottom width-70 blue-font text-center">
                    {salesInfo.customer}
                  </div>
                </div>
                <div className="flex margin-top-15px">
                  <div className="right-align width-30">PO #:</div>
                  <div className="border-bottom width-70 blue-font text-center">
                    {salesInfo.po}
                  </div>
                </div>
              </div>
              <div className="width-50">
                <div className="flex margin-top-15px">
                  <div className="right-align width-30">Sales Rep:</div>
                  <div className="border-bottom width-70 blue-font text-center">
                  {salesInfo.settingInfo?.name + " - " + salesInfo.settingInfo?.wwSalesRep}
                  </div>
                </div>
                <div className="flex margin-top-15px">
                  <div className="right-align width-30">Date:</div>
                  <div className="border-bottom width-70 blue-font text-center">
                    {salesInfo.date}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-content__space-between width-100">
            <div className="msh__hover-left-table">
              <table className="msh__header__left-table">
                <tbody>
                  <tr>
                    <td className="text-right">TYPE OF WINDOW TEAROUTS</td>
                    <td>
                      {viewMode !== 'homepage' ? (
                        measuresheetData.windowTable.tearouts
                      ) : (
                        <select
                          className="ms_select"
                          id="tearouts"
                          onChange={(e) => handleChangeWindowOption(e)}
                          value={measuresheetData.windowTable.tearouts}
                        >
                          <option value="WOOD">WOOD</option>
                          <option value="ALUM">ALUM</option>
                          <option value="VINYL">VINYL</option>
                          <option value="STEEL">STEEL</option>
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">TYPE OF WINDOW POCKET</td>
                    <td>
                      {viewMode !== 'homepage' ? (
                        measuresheetData.windowTable.pockets
                      ) : (
                        <select
                          className="ms_select"
                          id="pockets"
                          onChange={(e) => handleChangeWindowOption(e)}
                          value={measuresheetData.windowTable.pockets}
                        >
                          <option value="WOOD">WOOD</option>
                          <option value="PLASTER">PLASTER</option>
                          <option value="DRYWALL">DRYWALL</option>
                          <option value="CONCRETE">CONCRETE</option>
                          <option value="OTHER">OTHER</option>
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">WINDOW CUTBACKS</td>
                    <td>
                      <select
                        className="ms_select"
                        id="cutbacksStr"
                        onChange={(e) => handleChangeWindowOption(e)}
                        value={measuresheetData.windowTable.cutbacksStr}
                      >
                        {Object.keys(cutbacks).map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">TYPE OF BUILDING</td>
                    <td>
                      {viewMode !== 'homepage' ? (
                        measuresheetData.windowTable.typeOfBuilding
                      ) : (
                        <select
                          className="ms_select"
                          id="typeOfBuilding"
                          onChange={(e) => handleChangeWindowOption(e)}
                          value={measuresheetData.windowTable.typeOfBuilding}
                        >
                          {buildingType &&
                            buildingType.map((value, index) => (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            ))}
                        </select>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex width-60 justify-content__space-around">
              <div className="measure-sheet__draw-box black-font width-30">
                {measuresheetData.drawingData[0].value ? (
                  ''
                ) : (
                  <div style={{ fontSize: '10px' }}>
                    Click to Draw
                    <FontAwesomeIcon
                      icon={faPencil}
                      style={{
                        fontSize: 20,
                        color: 'black',
                        margin: '0px 20px',
                      }}
                    />
                  </div>
                )}

                <DrawBox
                  width={'100%'}
                  height={'100%'}
                  signId="drawingData"
                  addClass="mySign"
                  imgInfo={measuresheetData.drawingData[0].value}
                  updateSign={handleSign}
                  signStatus={viewMode === 'homepage'}
                  viewMode={viewMode}
                  style={measuresheetData.drawingData[0].style}
                  index={0}
                />
              </div>
              <div className="measure-sheet__draw-box black-font width-30">
                {measuresheetData.drawingData[1].value ? (
                  ''
                ) : (
                  <div style={{ fontSize: '10px' }}>
                    Click to Draw
                    <FontAwesomeIcon
                      icon={faPencil}
                      style={{
                        fontSize: 20,
                        color: 'black',
                        margin: '0px 20px',
                      }}
                    />
                  </div>
                )}
                <DrawBox
                  width={'100%'}
                  height={'100%'}
                  signId="drawingData"
                  addClass="mySign"
                  imgInfo={measuresheetData.drawingData[1].value}
                  updateSign={handleSign}
                  signStatus={viewMode === 'homepage'}
                  viewMode={viewMode}
                  style={measuresheetData.drawingData[1].style}
                  index={1}
                />
              </div>
              <div className="measure-sheet__draw-box black-font width-30">
                {measuresheetData.drawingData[2].value ? (
                  ''
                ) : (
                  <div style={{ fontSize: '10px' }}>
                    Click to Draw
                    <FontAwesomeIcon
                      icon={faPencil}
                      style={{
                        fontSize: 20,
                        color: 'black',
                        margin: '0px 20px',
                      }}
                    />
                  </div>
                )}
                <DrawBox
                  width={'100%'}
                  height={'100%'}
                  signId="drawingData"
                  addClass="mySign"
                  imgInfo={measuresheetData.drawingData[2].value}
                  updateSign={handleSign}
                  signStatus={viewMode === 'homepage'}
                  viewMode={viewMode}
                  style={measuresheetData.drawingData[2].style}
                  index={2}
                />
              </div>
            </div>
            <div>
              <table className="msh__header__right-table">
                <tbody>
                  <tr>
                    <td className="text-right">GRID STYLE</td>
                    <td className="text-center">
                      {viewMode !== 'homepage' ? (
                        measuresheetData.typeTable.gridStyle
                      ) : (
                        <select
                          className="ms_select"
                          id="gridStyle"
                          onChange={(e) => handleChangeTypeTable(e)}
                          value={measuresheetData.typeTable.gridStyle}
                        >
                          {gridStyle.normalType.map((value, index) => (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">CAPPING STYLE</td>
                    <td className="text-center">
                      {viewMode !== 'homepage' ? (
                        measuresheetData.typeTable.capping
                      ) : (
                        <select
                          className="ms_select"
                          id="capping"
                          onChange={(e) => handleChangeTypeTable(e)}
                          value={measuresheetData.typeTable.capping}
                        >
                          <option value="BRICKMOLD">BRICKMOLD</option>
                          <option value="1x4">1x4</option>
                          <option value="1x6">1x6</option>
                          <option value="OTHER">OTHER</option>
                        </select>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">TRIM COLOR</td>
                    <td className="text-center">
                      <input
                        className="non-outline"
                        id="trimColor"
                        value={measuresheetData.typeTable.trimColor}
                        onChange={(e) => handleChangeTypeTable(e)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-right">TEXTURE</td>
                    <td className="text-center">
                      {viewMode !== 'homepage' ? (
                        measuresheetData.typeTable.texture
                      ) : (
                        <select
                          className="ms_select"
                          id="texture"
                          onChange={(e) => handleChangeTypeTable(e)}
                          value={measuresheetData.typeTable.texture}
                        >
                          {texture.map((value, index) => (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="msh__body">
        <table className="msh__body-table">
          <TableHeader />
          <TableBody />
        </table>
      </div>
      <Modal
        isOpen={openTableModal}
        className="msh__header_main-table-modal"
        overlayClassName="myoverlay"
        closeTimeoutMS={200}
        ariaHideApp={false}
      >
        <div>
          {copyTemp !== null && (
            <button
              className="btn sign-modal-btn"
              style={{ float: 'right' }}
              onClick={() => handlePaste()}
            >
              Paste
            </button>
          )}
          {tempObj.room && tempObj.style && (
            <button
              className="btn sign-modal-btn"
              style={{ float: 'right' }}
              onClick={() => handleCopy()}
            >
              Copy
            </button>
          )}
          <div className="p-line">
            <label htmlFor="room">Room</label>
            <select
              id="room"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.room}
            >
              {roomItems.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="style">Style</label>
            <select
              id="style"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.style}
            >
              {roomStyle.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="roWidth">R.O.Width</label>
            <input
              id="roWidth"
              value={tempObj['roWidth']}
              onChange={(e) => handleChangeInput(e)}
            />
          </div>
          <div className="p-line">
            <label htmlFor="roHeight">R.O.Height</label>
            <input
              id="roHeight"
              value={tempObj['roHeight']}
              onChange={(e) => handleChangeInput(e)}
            />
          </div>
          <div className="p-line">
            <label htmlFor="orderWidth">Order Width</label>
            <input
              id="orderWidth"
              value={tempObj['orderWidth']}
              onChange={(e) => handleChangeInput(e)}
              disabled={tempObj.style === 'SPD' ? false : true}
            />
          </div>
          <div className="p-line">
            <label htmlFor="orderHeight">Order Height</label>
            <input
              id="orderHeight"
              value={tempObj['orderHeight']}
              onChange={(e) => handleChangeInput(e)}
              disabled={tempObj.style === 'SPD' ? false : true}
            />
          </div>
          <div className="p-line">
            <label htmlFor="grids">Grids/Blinds</label>
            <input
              id="grids"
              value={tempObj['grids']}
              onChange={(e) => handleChangeInput(e)}
            />
          </div>
          <div className="p-line">
            <label htmlFor="intColor">Int Color</label>
            <select
              id="intColor"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.intColor}
            >
              {interiorColor.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="extColor">Ext Color</label>
            <select
              id="extColor"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.extColor}
            >
              {exteriorColor.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="foam">FOAM</label>
            <div className="measure-sheet__check-box">
              <Checkbox
                checkVal={tempObj.foam}
                checkId="foam"
                updateCheck={handleChangeCheckbox}
                isInputEnable={viewMode === 'homepage'}
                type={typeOfCheckBox.PatioDoorOrder}
              />
            </div>
          </div>
          <div className="p-line">
            <label htmlFor="temp">Temp</label>
            <select
              id="temp"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.temp}
            >
              {temp.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="obsc">OBSC</label>
            <select
              id="obsc"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.obsc}
            >
              {obsc.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="energy">Energy</label>
            <select
              id="energy"
              onChange={(e) => handleChangeInput(e)}
              value={tempObj.energy}
            >
              {energy.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="p-line">
            <label htmlFor="mullCuts">Mull Cuts</label>
            <input
              id="mullCuts"
              value={tempObj['mullCuts']}
              onChange={(e) => handleChangeInput(e)}
            />
          </div>
          <div className="p-line">
            <label htmlFor="notes">Notes</label>
            <input
              id="notes"
              value={tempObj['notes']}
              onChange={(e) => handleChangeInput(e)}
            />
          </div>
        </div>
        <div className="modal_footer">
          <button className="btn sign-modal-btn" onClick={handleSave}>
            Save
          </button>
          <button className="btn sign-modal-btn" onClick={handleClear}>
            Clear
          </button>
          <button
            className="btn sign-modal-btn"
            onClick={() => setOpenTableModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MeasureSheet;
