import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import HomePage from '../containers/HomePage';
import LandingPage from '../containers/LandingPage';
import Contact from '../containers/Contact';
import OpenPage from '../containers/OpenPage';
import DayContract from '../containers/DayContract';
import NewPage from '../containers/NewPage';
import CreateCustomer from '../containers/CreateCustomer';
import Customer from '../containers/Customer';
import CreateContract from '../containers/CreateContract';
import Measure from '../containers/Measure';
import MeasureRow from '../containers/Measure/MeasureRow';
import Summary from '../containers/Measure/Summary';
import SignPage from '../containers/SignPage';
import ConvertPDFPage from '../containers/ConvertPDFPage';
import ConvertConsultantPage from '../containers/ConvertConsultantPage';
import ConvertCommissionPage from '../containers/ConvertCommissionPage';
import Setting from '../containers/Setting';
import AuthProvider from '../components/AuthProvider';
import AuthPage from '../containers/AuthPage';
import ForgotPage from '../containers/AuthPage/forgot';
import ResetPage from '../containers/AuthPage/reset';
import Layout from '../layout/Layout';
import Verify from '../containers/Verify';

const Router = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
            path="/documentation"
          />
          <Route
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
            path="/demo/documentation"
          />
          <Route
            element={
              <Layout>
                <NewPage />
              </Layout>
            }
            path="/new"
          />
          <Route
            element={
              <Layout>
                <CreateCustomer />
              </Layout>
            }
            path="/new/customer"
          />
          {/* <Route
            element={
              <Layout>
                <Customer />
              </Layout>
            }
            path="/new/customer/:customer"
          /> */}
          {/* <Route
            element={
              <Layout>
                <CreateContract />
              </Layout>
            }
            path="/new/customer/:customer/createcontract"
          /> */}
          <Route
            element={
              <Layout>
                <Measure />
              </Layout>
            }
            path="/new/customer/:customer/createcontract/measure"
          />
          <Route
            element={
              <Layout>
                <MeasureRow />
              </Layout>
            }
            path="/new/customer/:customer/createcontract/measure/:id"
          />

          <Route
            element={
              <Layout>
                <Summary />
              </Layout>
            }
            path="/new/customer/:customer/createcontract/measuresummary"
          />
          <Route
            element={
              <Layout>
                <OpenPage />
              </Layout>
            }
            path="/open"
          />
          <Route
            element={
              <Layout>
                <DayContract />
              </Layout>
            }
            path="/open/:id"
          />
          <Route
            element={
              <Layout>
                <AuthPage isLogin />
              </Layout>
            }
            path="/"
          />
          <Route
            element={
              <Layout>
                <ForgotPage/>
              </Layout>
            }
            path="/forgot-password"
          />
          <Route
            element={
              <Layout>
                <ResetPage/>
              </Layout>
            }
            path="/forgot-password-reset/:verify"
          />
          <Route
            element={
              <Layout>
                <Verify />
              </Layout>
            }
            path="/verify/:verify"
          />
          <Route
            element={
              <Layout>
                <AuthPage />
              </Layout>
            }
            path="/admin"
          />
          <Route
            element={
              <Layout>
                <Setting />
              </Layout>
            }
            path="/setting"
          />
          <Route element={<SignPage />} path="/contract/:id" />
          <Route
            element={<ConvertPDFPage />}
            path="/convert-pdf-customer/:id"
          />
          <Route
            element={<ConvertConsultantPage />}
            path="/convert-pdf-consultant/:id"
          />
          <Route
            element={<ConvertCommissionPage />}
            path="/convert-pdf-commission/:id"
          />
          <Route
            path="/contact"
            element={
              <Layout>
                <Contact />
              </Layout>
            }
          />
          <Route
            path="*"
            element={
              <Layout>
                <LandingPage />
              </Layout>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;
