import * as React from 'react';
import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const DraggableList = React.memo(
  ({ items, onDragEnd, onChangeTextArea, onChangeRoleArea, onCancel }) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {items.map((item, index) => (
                <DraggableListItem
                  item={item}
                  index={index}
                  key={item.id}
                  value={item.value}
                  onChange={onChangeTextArea}
                  onChangeRoleArea={onChangeRoleArea}
                  onCancel={onCancel}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableList;
