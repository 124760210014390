import axios from 'axios';
import { API_URL } from '../constants';

export async function login({ email, password }) {
  const url = 'auth/login';
  try {
    const response = axios.post(`${API_URL}/${url}`, {
      email: email,
      password: password,
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function register({ email, name, phone }) {
  const url = 'auth/register';
  try {
    const response = axios.post(`${API_URL}/${url}`, {
      email: email,
      name: name,
      phone: phone,
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function getAllContractByDays({ customer, creator }) {
  const url = 'contract/open';
  //console.log(customer, creator);
  try {
    const response = axios.post(`${API_URL}/${url}?customer=${customer}`, {
      creator: creator,
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function getAllContracts({ date, creator }) {
  const url = 'contract/open/contracts';
  //console.log(date, creator);
  try {
    const response = axios.post(`${API_URL}/${url}?date=${date}`, {
      creator: creator,
    });
    return response;
  } catch (err) {
    return null;
  }
}
export async function getAllContractById({ id }) {
  const url = 'contract/open/contracts';
  //console.log(id);
  try {
    const response = axios.get(`${API_URL}/${url}/${id}`);
    return response;
  } catch (err) {
    return null;
  }
}

export async function getContractBefore({ email }) {
  const url = 'contract/email';
  try {
    const response = axios.get(`${API_URL}/${url}?customer_email=${email}`);
    return response;
  } catch (err) {
    return null;
  }
}
export async function getUsers() {
  const url = 'auth/users';
  try {
    const response = axios.get(`${API_URL}/${url}`);
    return response;
  } catch (err) {
    return null;
  }
}
export async function getUsersSettingInfo({ email }) {
  const url = 'auth/usersettinginfo';
  try {
    const response = axios.post(`${API_URL}/${url}`, {
      email: email,
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function saveUsersSettingInfo({ email, settingInfo }) {
  const url = 'auth/usersettinginfo/save';
  try {
    const response = axios.post(`${API_URL}/${url}`, {
      email: email,
      settingInfo: settingInfo,
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function verify(verify) {
  const url = 'auth/verify';
  try {
    const response = axios.post(`${API_URL}/${url}/${verify}`);
    return response;
  } catch (err) {
    return null;
  }
}
export async function forgot({email}) {
  const url = 'auth/forgot';
  try {
    const response = axios.post(`${API_URL}/${url}`, {
      email: email
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function contact({name, phone, email, location, betatester, message}) {
  const url = 'auth/contact';
  try {
    const response = axios.post(`${API_URL}/${url}`, {
      name : name,
      phone : phone,
      email : email,
      location : location,
      betatester: betatester,
      message : message
    });
    return response;
  } catch (err) {
    return null;
  }
}

export async function updatePassword(verify, password) {
  const url = 'auth/users';
  try {
    const response = axios.post(`${API_URL}/${url}/${verify}`, {
      password: password,
    });
    return response;
  } catch (err) {
    return null;
  }
}
