import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    salesperson: window.location.pathname.split('/')[1] === 'demo' ? 'Nick Tisdale' : '',
    signature: {},
  },
};

export const salespersonSlice = createSlice({
  name: 'salesperson',
  initialState,
  reducers: {
    updateValue: (state, action) => {
      state.data[action.payload.id] = action.payload.value || '';
    },
    updateDataSalesperson: (state, action) => {
      state.data = action.payload;
    },
    init: (state, action) => {
      state.data = initialState.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateValue, updateDataSalesperson, init } =
  salespersonSlice.actions;

export default salespersonSlice.reducer;
