import React, { useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { BorderColor, InsertDriveFile, FileCopy } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import styled from '@emotion/styled';
import { useAuthDataContext } from '../../components/AuthProvider';

//////////////////////////////////
const RootStyle = styled('div')({
  height: '80vh',
  display: 'grid',
});

const ContentStyle = styled('div')({
  maxWidth: 480,
  padding: 25,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fff',
});

const New = () => {
  const navigate = useNavigate();
  const { authData, isdemo } = useAuthDataContext();
  useEffect(() => navigate('/new/customer'));
  useEffect(() => {
    if (isdemo || !authData.email) {
      navigate('/');
    }
  }, [authData, isdemo]);
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <List component="nav" aria-label="main mailbox folders">
            <ListItemButton
              onClick={(event) => navigate('/new/customer')}
              sx={{ padding: 3 }}
            >
              <ListItemIcon>
                <BorderColor fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Customer" />
            </ListItemButton>
            <ListItemButton
              onClick={(event) => navigate('/new/contract')}
              sx={{ padding: 3 }}
            >
              <ListItemIcon>
                <InsertDriveFile fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Contract" />
            </ListItemButton>
            <ListItemButton
              onClick={(event) => navigate('/new/documnet')}
              sx={{ padding: 3 }}
            >
              <ListItemIcon>
                <FileCopy fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Document" />
            </ListItemButton>
          </List>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default New;
