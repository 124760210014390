import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Home, Help, AddBox, Work, Adjust, Mail } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthDataContext } from '../components/AuthProvider';

const Footer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authData, isdemo } = useAuthDataContext();
  return (
    <Grid container spacing={0} style={{ paddingBottom: 40 }}>
      <Grid item={true} xs={2}>
        <Link
          component={RouterLink}
          to="/"
          onClick={() => {
            if (!isdemo) return;

            window.location.assign('https://measureform.com/');
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Home fontSize="large" sx={{ color: '#fff' }} />
          <span style={{ color: 'white' }}>Home</span>
        </Link>
      </Grid>
      <Grid item={true} xs={2}>
        {!isdemo && authData.email ? (
          <Link
            component={RouterLink}
            to="/new/customer"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <AddBox fontSize="large" sx={{ color: '#fff' }} />
            <span style={{ color: 'white' }}>New</span>
          </Link>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item={true} xs={2}>
        {!isdemo && authData.email ? (
          <Link
            component={RouterLink}
            to="/open"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Work fontSize="large" sx={{ color: '#fff' }} />
            <span style={{ color: 'white' }}>Open</span>
          </Link>
        ) : (
          <></>
        )}
      </Grid>
      <Grid item={true} xs={2}></Grid>
      <Grid item={true} xs={2}>
        {pathname === '/' && <Link
          component={RouterLink}
          to="/contact"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
          <Mail fontSize="large" sx={{ color: '#fff' }} />
          <span style={{ color: 'white' }}>Contact</span>
        </Link>}
      </Grid>
      <Grid item={true} xs={2}>
        {pathname === '/' && (
          <Link
            component={RouterLink}
            to="/contact"
            onClick={() => {
              // window.location.assign(
              //   'https://measureform.com/demo/documentation'
              //   // 'http://localhost:3000/demo' /**/
              // );
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Adjust fontSize="large" sx={{ color: '#fff' }} />
            <span style={{ color: 'white' }}>Demo</span>
          </Link>
        )}
        {pathname !== '/' && (
          <Link
            component={RouterLink}
            to="/contact"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Mail fontSize="large" sx={{ color: '#fff' }} />
            <span style={{ color: 'white' }}>Contact</span>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

export default Footer;
