import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import { FormControl, InputLabel, Input, FormHelperText } from '@mui/material';

const DraggableListItem = ({ item, index, value, onChange, onChangeRoleArea, onCancel }) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="item">
            <div className="item-header">
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '16px' }}
              >
                <FormatLineSpacingIcon />
                <FormControl error={item.id == ''} variant="standard">
                  <Input value={item.role} name={item.id} onChange={onChangeRoleArea} />
                </FormControl>
              </div>
              <button className="cancel-btn" onClick={() => onCancel(item.id)}>
                X
              </button>
            </div>
            <div className="item-body">
              <textarea
                rows={4}
                name={item.id}
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
