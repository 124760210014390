import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Stack, Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { formatDate } from '../../utils/globals';
import { updateValue } from '../../store/slices/contactSlice';
import { updateSalesInfo } from '../../store/slices/salesInfoSlice';
import { updateValue as updateSalesMan } from '../../store/slices/salesmanSlice';

import { updateValue as updateCreditValue } from '../../store/slices/creditSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getContractBefore } from '../../utils/authApi';
import { getUsersSettingInfo, saveUsersSettingInfo } from '../../utils/authApi';
import { useAuthDataContext } from '../AuthProvider';

import { store } from '../../store/store';
import { updateDataContact } from '../../store/slices/contactSlice';
import { updateDataBottompage } from '../../store/slices/bottompage3Slice';
import { updateDataCalculate } from '../../store/slices/calculateSlice';
import { updateDataCredit } from '../../store/slices/creditSlice';
import { updateDataGlassoption } from '../../store/slices/glassoptionSlice';
import { updateDataJobinfo } from '../../store/slices/jobinfoSlice';
import { updateDataMiscellenous } from '../../store/slices/miscellenousSlice';
import { updateDataPrebuilt } from '../../store/slices/prebuiltSlice';
import { updateDataRoundup } from '../../store/slices/roundupSlice';
import { updateDataSalesman } from '../../store/slices/salesmanSlice';
import { updateDataSalesperson } from '../../store/slices/salespersonSlice';
import { updateDataTable23 } from '../../store/slices/table23Slice';
import { updateDataTable33 } from '../../store/slices/table33Slice';
import { updateDataTable43 } from '../../store/slices/table43Slice';
import { updateDataVinylsliding } from '../../store/slices/vinylslidingSlice';
import { updateDataWindowoption } from '../../store/slices/windowoptionSlice';
import { updateDataWindowtable } from '../../store/slices/windowtableSlice';
import { updateDataWindowworld } from '../../store/slices/windowworldSlice';
import { updateDataMeasureSheet } from '../../store/slices/measuresheetSlice';
import { updateDataPatioDoor } from '../../store/slices/patioDoorOrderSlice';
import { updateDataWindowOrder } from '../../store/slices/windoworderSlice';
import { updateDataSalesInfo } from '../../store/slices/salesInfoSlice';

import { init as initDataContact } from '../../store/slices/contactSlice';
import { init as initDataBottompage } from '../../store/slices/bottompage3Slice';
import { init as initDataCalculate } from '../../store/slices/calculateSlice';
import { init as initDataCredit } from '../../store/slices/creditSlice';
import { init as initDataGlassoption } from '../../store/slices/glassoptionSlice';
import { init as initDataJobinfo } from '../../store/slices/jobinfoSlice';
import { init as initDataMiscellenous } from '../../store/slices/miscellenousSlice';
import { init as initDataPrebuilt } from '../../store/slices/prebuiltSlice';
import { init as initDataRoundup } from '../../store/slices/roundupSlice';
import { init as initDataSalesman, updateValue as updateValueSalesman } from '../../store/slices/salesmanSlice';
import { init as initDataSalesperson, updateValue as updateValueSalesperson } from '../../store/slices/salespersonSlice';
import { init as initDataTable23 } from '../../store/slices/table23Slice';
import { init as initDataTable33 } from '../../store/slices/table33Slice';
import { init as initDataTable43 } from '../../store/slices/table43Slice';
import { init as initDataVinylsliding } from '../../store/slices/vinylslidingSlice';
import { init as initDataWindowoption } from '../../store/slices/windowoptionSlice';
import { init as initDataWindowtable } from '../../store/slices/windowtableSlice';
import { init as initDataWindowworld } from '../../store/slices/windowworldSlice';
import { init as initDataMeasureSheet } from '../../store/slices/measuresheetSlice';
import { init as initDataPatioDoor } from '../../store/slices/patioDoorOrderSlice';
import { init as initDataWindowOrder } from '../../store/slices/windoworderSlice';
import { init as initDataSalesInfo } from '../../store/slices/salesInfoSlice';

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const CreateCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authData } = useAuthDataContext();

  useEffect(()=> {
    async function fetchData(authData) {
      const settingInfoData = await getUsersSettingInfo({
        email: authData.email,
      });
      const info = JSON.parse(settingInfoData?.data[0]?.setting_info || '{}');
      // console.log(authData, info);
      dispatch(updateSalesInfo({ id: 'repNumber', value: info.wwSalesRep }));
      dispatch(updateSalesInfo({ id: 'wwOrder', value: info.wwStoreNumber }));
      dispatch(updateSalesInfo({ id: 'salesLocation', value: info.wwLocation }));
      dispatch(updateSalesInfo({ id: 'settingInfo', value: info }));
    }

    fetchData(authData);
  }, [authData]);

  const SignupSchema = Yup.object().shape({
    phone: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
      // .required('Phone required'),
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
      // .required('Name required'),
    address: Yup.string(),
    //.required('Adress is required'),
    email: Yup.string()
      .email('Email must be a valid email address')
      // .required('Email is required'),
  });

  const HeadingStyle = styled(Box)({
    textAlign: 'center',
  });
  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.6, ease: easing },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: easing,
      },
    },
  };
  const updateStore = (data) => {
    store.dispatch(updateDataContact(data.contact.data));
    store.dispatch(updateDataBottompage(data.bottompage3.data));
    store.dispatch(updateDataCalculate(data.calculate.data));
    store.dispatch(updateDataCredit(data.credit.data));
    store.dispatch(updateDataGlassoption(data.glassoption.data));
    store.dispatch(updateDataPrebuilt(data.prebuilt.data));
    store.dispatch(updateDataJobinfo(data.jobinfo.data));
    store.dispatch(updateDataMiscellenous(data.miscellenous.data));
    store.dispatch(updateDataRoundup(data.roundup.data));
    store.dispatch(updateDataSalesman(data.salesman.data));
    store.dispatch(updateDataSalesperson(data.salesperson.data));
    store.dispatch(updateDataTable23(data.table23.data));
    store.dispatch(updateDataTable33(data.table33.data));
    store.dispatch(updateDataTable43(data.table43.data));
    store.dispatch(updateDataVinylsliding(data.vinylsliding.data));
    store.dispatch(updateDataWindowoption(data.windowoption.data));
    store.dispatch(updateDataWindowtable(data.windowtable.data));
    store.dispatch(updateDataWindowworld(data.windowworld.data));
    store.dispatch(updateDataMeasureSheet(data.measuresheet.data));
    store.dispatch(updateDataPatioDoor(data.patiodoororder.data));
    store.dispatch(updateDataWindowOrder(data.windoworder.data));
    store.dispatch(updateDataSalesInfo(data.salesInfo.data));
  };
  const initStore = () => {
    store.dispatch(initDataContact());
    store.dispatch(initDataBottompage());
    store.dispatch(initDataCalculate());
    store.dispatch(initDataCredit());
    store.dispatch(initDataGlassoption());
    store.dispatch(initDataPrebuilt());
    store.dispatch(initDataJobinfo());
    store.dispatch(initDataMiscellenous());
    store.dispatch(initDataRoundup());
    store.dispatch(updateValueSalesman({id: 'signature'}));
    store.dispatch(updateValueSalesperson({id: 'signature'}));
    store.dispatch(initDataTable23());
    store.dispatch(initDataTable33());
    store.dispatch(initDataTable43());
    store.dispatch(initDataVinylsliding());
    store.dispatch(initDataWindowoption());
    store.dispatch(initDataWindowtable());
    store.dispatch(initDataWindowworld());
    store.dispatch(initDataMeasureSheet());
    store.dispatch(initDataPatioDoor());
    store.dispatch(initDataWindowOrder());
    store.dispatch(updateSalesInfo({id: 'date', value: formatDate(new Date()) }));
    store.dispatch(updateValueSalesman({id: 'date1', value: formatDate(new Date()) }));
    store.dispatch(updateValueSalesman({id: 'date3', value: formatDate(new Date()) }));

    // console.log("================");
  };
  const formik = useFormik({
    initialValues: {
      phone: '', //JSON.parse(window.localStorage.getItem('customer-form') || '{}').phone || '',
      name: '', //JSON.parse(window.localStorage.getItem('customer-form') || '{}').name || '',
      address: '', //JSON.parse(window.localStorage.getItem('customer-form') || '{}').address || '',
      email: '', //JSON.parse(window.localStorage.getItem('customer-form') || '{}').email || '',
    },
    validationSchema: SignupSchema,
    onSubmit: (e) => {
      initStore();
      dispatch(updateValue({ id: 'customer', value: e.name }));
      dispatch(updateValue({ id: 'installAddr', value: e.address }));
      dispatch(updateValue({ id: 'phone1', value: e.phone }));
      dispatch(updateSalesInfo({ id: 'customer', value: e.name }));
      dispatch(updateSalesInfo({ id: 'email', value: e.email }));
      dispatch(updateSalesMan({ id: 'email', value: e.email }));
      dispatch(updateCreditValue({ id: 'cc_name', value: e.name }));
      dispatch(updateCreditValue({ id: 'cc_address', value: e.email }));
      dispatch(updateCreditValue({ id: 'cc_phone', value: e.phone }));
      formik.setSubmitting(false);
      window.localStorage.setItem('customer-form', JSON.stringify({
        phone: e.phone,
        name: e.name,
        address: e.address,
        email: e.email,
      }))
      navigate('/new/customer/:customer/createcontract/measure');
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <HeadingStyle>
        <Typography
          variant="h3"
          sx={{ color: 'text.secondary', pb: 3, textAlign: 'left' }}
        >
          Customer
        </Typography>
      </HeadingStyle>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack
            initial={{ opacity: 0, y: 60 }}
            animate={animate}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>

          <Stack spacing={3} initial={{ opacity: 0, y: 40 }} animate={animate}>
            <TextField
              fullWidth
              autoComplete="address"
              type="text"
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Box initial={{ opacity: 0, y: 20 }} animate={animate}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Create
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default CreateCustomer;
