import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TextareaAutosize } from '@mui/base';
import {
  Stack,
  Box,
  Link,
  TextField,
  Checkbox,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import styled from '@emotion/styled';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contact } from '../../utils/authApi';

/////////////////////////////////////////////////////////////
let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const SignupForm = () => {
  const navigate = useNavigate();

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Name required'),
    phone: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Phone required'),
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    location: Yup.string()
      .min(5, 'Too Short!')
      .max(15, 'Too Long!')
      .required('Location is required'),
    message: Yup.string()
      .min(5, 'Too Short!')
      .max(1000, 'Too Long!')
      .required('Message is required'),
  });

  const HeadingStyle = styled(Box)({
    textAlign: 'center',
  });
  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.6, ease: easing },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: easing,
      },
    },
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      location: '',
      betatester: false,
      message: '',
    },
    validationSchema: SignupSchema,
    onSubmit: (e) => {
      const res = contact({ ...e })
        .then(({ data }) => {
          toast.success('Success!');
          formik.setSubmitting(false);
        })
        .catch((err) => {
          toast.error('Something went wrong.');
          formik.setSubmitting(false);
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <HeadingStyle>
        <Typography
          variant="h4"
          sx={{ color: 'text.secondary', pb: 3, textAlign: 'left' }}
        >
          Contact
        </Typography>
      </HeadingStyle>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack
            initial={{ opacity: 0, y: 60 }}
            animate={animate}
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
          >
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Phone"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Stack>

          <Stack spacing={3} initial={{ opacity: 0, y: 40 }} animate={animate}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          <Stack spacing={3} initial={{ opacity: 0, y: 40 }} animate={animate}>
            <TextField
              fullWidth
              autoComplete="location"
              label="location"
              {...getFieldProps('location')}
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location}
            />
          </Stack>

          <Stack spacing={3} initial={{ opacity: 0, y: 40 }} animate={animate}>
            <FormControlLabel
              control={
                <Checkbox {...getFieldProps('betatester')}
                error={Boolean(touched.betatester && errors.betatester)}
                helperText={touched.betatester && errors.betatester}/>
              }
              label="Would you like to be a beta tester?"
            />
          </Stack>

          <Stack spacing={3} initial={{ opacity: 0, y: 40 }} animate={animate}>
            <TextField
              fullWidth
              autoComplete="message"
              label="message"
              {...getFieldProps('message')}
              error={Boolean(touched.message && errors.message)}
              helperText={touched.message && errors.message}
              rows={4}
              multiline
            />
          </Stack>

          <Box initial={{ opacity: 0, y: 20 }} animate={animate}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Send
            </LoadingButton>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default SignupForm;
