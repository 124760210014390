import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    name: '',
    email: '',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    },
    init: (state, action) => {
      state.data = initialState.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData, init } = userSlice.actions;

export default userSlice.reducer;
