import { createSlice } from '@reduxjs/toolkit';
import {
  gridStyle,
  initDataOfMeasureSheet,
  cutbacks,
  texture,
} from '../../constants/variables';

const initialState = {
  data: {
    windowTable: {
      tearouts: 'WOOD',
      pockets: 'WOOD',
      cutbacksStr: '(-3/8") X (-3/8")',
      cutbacks: cutbacks['(-3/8") X (-3/8")'],
      typeOfBuilding: 'BRICK',
    },
    typeTable: {
      gridStyle: gridStyle.normalType[0],
      capping: 'BRICKMOLD',
      trimColor: '',
      texture: '',
    },
    mainTable: {},
    drawingData: [{}, {}, {}],
  },
};

for (let i = 0; i < 40; i++) {
  initialState.data.mainTable[i] = {
    ...initDataOfMeasureSheet,
    no: i + 1,
  };
}

export const measuresheetSlice = createSlice({
  name: 'measuresheet',
  initialState,
  reducers: {
    updateWindowTable: (state, action) => {
      state.data.windowTable = { ...action.payload };
    },
    updateTypeTable: (state, action) => {
      state.data.typeTable = { ...action.payload };
    },
    updateMainTable: (state, action) => {
      state.data.mainTable = { ...action.payload };
    },
    updateDataMeasureSheet: (state, action) => {
      state.data = action.payload;
    },
    updateDrawingDataFunc: (state, action) => {
      state.data.drawingData[action.payload.index] = {
        value: action.payload.value || '',
        style: action.payload.style,
      };
    },
    init: (state, action) => {
      state.data = initialState.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateWindowTable,
  updateTypeTable,
  updateMainTable,
  updateDataMeasureSheet,
  updateDrawingDataFunc,
  init,
} = measuresheetSlice.actions;

export default measuresheetSlice.reducer;
