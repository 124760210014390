import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import jwt from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { updateValue } from '../../store/slices/salespersonSlice';
import { updateValue as updateSalesman } from '../../store/slices/salesmanSlice';
import { getUsersSettingInfo, saveUsersSettingInfo } from '../../utils/authApi';
import { updateSalesInfo } from '../../store/slices/salesInfoSlice';

export const AuthDataContext = createContext(null);

const initialAuthData = {};

const AuthDataProvider = (props) => {
  const [authData, setAuthData] = useState(initialAuthData);
  const [isdemo, setIsdemo] = useState(true);
  const dispatch = useDispatch();

  /* The first time the component is rendered, it tries to
   * fetch the auth data from a source, like a cookie or
   * the localStorage.
   */
  useEffect(() => {
    const currentAuthData = localStorage.getItem('token');
    const test = window.location.pathname.split('/')[1] === 'demo'; //window.location.pathname.indexOf('demo') >= 0;
    setIsdemo(test);
    if (currentAuthData && currentAuthData!='undefined') {
      const jwtPayload = jwt(currentAuthData);
      if (jwtPayload.exp * 1000 < Date.now()) {
        setAuthData({});
      } else {
        setAuthData(jwtPayload.user);
        // console.log(jwtPayload.user);
        dispatch(
          updateSalesman({ id: 'salesperson', value: jwtPayload.user.name })
        );
        dispatch(updateValue({ id: 'salesman', value: jwtPayload.user.name }));
        dispatch(
          updateSalesman({ id: 'salesman', value: jwtPayload.user.name })
        );
        dispatch(
          updateValue({ id: 'salesperson', value: jwtPayload.user.name })
        );
      }
    }
  }, []);

  const onLogout = () => setAuthData(initialAuthData);

  const onLogin = (newAuthData) => setAuthData(newAuthData);

  const authDataValue = useMemo(
    () => ({ authData, onLogin, onLogout, isdemo }),
    [authData]
  );

  useEffect(()=> {
    async function fetchData(authData) {
      const settingInfoData = await getUsersSettingInfo({
        email: authData.email,
      });
      const info = JSON.parse(settingInfoData?.data[0]?.setting_info || '{}');
      // console.log(authData, info);
      dispatch(updateSalesInfo({ id: 'repNumber', value: info.wwSalesRep }));
      dispatch(updateSalesInfo({ id: 'wwOrder', value: info.wwStoreNumber }));
      dispatch(updateSalesInfo({ id: 'salesLocation', value: info.wwLocation }));
      dispatch(updateSalesInfo({ id: 'settingInfo', value: info }));
    }

    fetchData(authData);
  }, [authData]);

  return (
    <AuthDataContext.Provider
      value={{ ...authDataValue, onLogin, onLogout }}
      {...props}
    />
  );
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
