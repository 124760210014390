import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import { updateMainTable } from '../../../store/slices/measuresheetSlice';
import { updateMainTable as updateMainTableForWindowOrder } from '../../../store/slices/windoworderSlice';

import {
  initDataOfMeasureSheet,
  nonComparisonElementArray,
  typeOfCheckBox,
  interiorColor,
  exteriorColor,
  roomItems,
  roomStyle,
  energy,
  obsc,
  temp,
  initDataOfWindowOrder,
} from '../../../constants/variables';

import { fractionCalculator } from '../../../utils/globals';

let data;

const dataForWindowOrder = {
  mainTable: {},
};

const MeasureSheet = ({ page }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const measuresheetData = useSelector((state) => state.measuresheet.data);
  data = { ...measuresheetData };
  const windowOrderData = useSelector((state) => state.windoworder.data);

  const viewMode = useSelector((state) => state.option.data.viewMode);

  dataForWindowOrder.mainTable = { ...windowOrderData.mainTable };

  const [openTableModal, setOpenTableModal] = useState(false);
  const [tempObj, setTempObj] = useState({});
  const [selectedRow, setSelectedRow] = useState(0);

  useEffect(() => {
    const row_id =
      location.pathname.split('/')[location.pathname.split('/').length - 1];
    setSelectedRow(row_id);
    const sindex = Object.values(measuresheetData.mainTable).find(
      (m) => m.room && m.style
    );
    setTempObj(
      sindex
        ? {
            ...measuresheetData.mainTable[row_id],
            intColor: sindex.intColor,
            extColor: sindex.extColor,
            foam: sindex.foam,
            energy: sindex.energy,
          }
        : { ...measuresheetData.mainTable[row_id] }
    );
  }, []);
  const dispatch = useDispatch();

  const handleChangeInput = (e) => {
    switch (e.target.name) {
      case 'style':
        setTempObj({
          ...tempObj,
          [e.target.name]: e.target.value,
          orderWidth:
            e.target.value === 'SPD'
              ? tempObj.roWidth
              : tempObj.roWidth
              ? e.target.value === 'DH2' ? fractionCalculator((parseInt(tempObj.roWidth)).toString(), '+',measuresheetData.windowTable.cutbacks.w) 
              : e.target.value === 'DH3' ? fractionCalculator((parseInt(tempObj.roWidth)).toString(), '+',measuresheetData.windowTable.cutbacks.w)
              : fractionCalculator(
                  tempObj.roWidth,
                  '+',
                  measuresheetData.windowTable.cutbacks.w
                )
              : '',
          orderHeight:
            e.target.value === 'SPD'
              ? tempObj.roHeight
              : tempObj.roHeight
              ? fractionCalculator(
                  tempObj.roHeight,
                  '+',
                  measuresheetData.windowTable.cutbacks.h
                )
              : '',
        });

        dataForWindowOrder.mainTable[0] = {
          ...dataForWindowOrder.mainTable[0],
          szWidth: tempObj.orderWidth,
        };
        dispatch(
          updateMainTableForWindowOrder({
            ...dataForWindowOrder.mainTable,
            0: { ...dataForWindowOrder.mainTable[0] },
          })
        );
        break;
      case 'roWidth':
        setTempObj({
          ...tempObj,
          [e.target.name]: e.target.value,
          orderWidth:
            tempObj.style === 'SPD'
              ? tempObj.orderWidth
              : e.target.value
              ? tempObj.style === 'DH2' ? fractionCalculator((parseInt(e.target.value)).toString(), '+',measuresheetData.windowTable.cutbacks.w) 
              : tempObj.style === 'DH3' ? fractionCalculator((parseInt(e.target.value)).toString(), '+',measuresheetData.windowTable.cutbacks.w)
              : fractionCalculator(
                  e.target.value,
                  '+',
                  measuresheetData.windowTable.cutbacks.w
                )
              : '',
        });

        break;
      case 'roHeight':
        setTempObj({
          ...tempObj,
          [e.target.name]: e.target.value,
          orderHeight:
            tempObj.style === 'SPD'
              ? tempObj.orderHeight
              : e.target.value
              ? fractionCalculator(
                  e.target.value,
                  '+',
                  measuresheetData.windowTable.cutbacks.h
                )
              : '',
        });
        break;
      default:
        setTempObj({
          ...tempObj,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const findLastCategoryNumKindBeforeIndex = (index) => {
    let result = -1;
    for (let i = 0; i < index; i++) {
      if (data.mainTable[i].categoryNum > result) {
        result = data.mainTable[i].categoryNum;
      }
    }

    return result;
  };

  const isOnlyOneCategory = (index) => {
    const length = Object.keys(data.mainTable).length;

    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else {
        if (
          Object.keys(data.mainTable[index]).every((key) => {
            if (nonComparisonElementArray.find((val) => val === key)) {
              return true;
            } else {
              return data.mainTable[i][key] === data.mainTable[index][key];
            }
          })
        )
          return false;
      }
    }
    return true;
  };

  const isOnlyOneCategoryInPreState = (index) => {
    const length = Object.keys(data.mainTable).length;
    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else {
        if (
          Object.keys(measuresheetData.mainTable[index]).every((key) => {
            if (nonComparisonElementArray.find((val) => val === key)) {
              return true;
            } else {
              return (
                measuresheetData.mainTable[i][key] ===
                measuresheetData.mainTable[index][key]
              );
            }
          })
        )
          return false;
      }
    }
    return true;
  };

  const findFirstIdenticalIndex = (index) => {
    const length = Object.keys(data.mainTable).length;
    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else if (
        Object.keys(data.mainTable[index]).every((key) => {
          if (nonComparisonElementArray.find((val) => val === key)) {
            return true;
          } else {
            return data.mainTable[i][key] === data.mainTable[index][key];
          }
        })
      ) {
        return i;
      }
    }
    return -1;
  };

  const findFirstIdenticalIndexBeforeChanging = (index) => {
    if (
      Object.keys(measuresheetData.mainTable[index]).every((key) => {
        if (nonComparisonElementArray.find((val) => val === key)) {
          return true;
        } else {
          return data.mainTable[index][key] === '';
        }
      })
    )
      return -1;
    const length = Object.keys(data.mainTable).length;
    for (let i = 0; i < length; i++) {
      if (i === index) continue;
      else {
        if (
          Object.keys(measuresheetData.mainTable[index]).every((key) => {
            if (nonComparisonElementArray.find((val) => val === key)) {
              return true;
            } else {
              return (
                data.mainTable[i][key] ===
                measuresheetData.mainTable[index][key]
              );
            }
          })
        ) {
          return i;
        }
      }
    }
    return -1;
  };

  const estimateCategoryNum = (index) => {
    if (data.mainTable[index].categoryNum === -1)
      data.mainTable[index].categoryNum = 0;

    let lastCategoryNum = findLastCategoryNumKindBeforeIndex(index);

    //console.log('???=>isOnly', isOnlyOneCategory(index));

    if (isOnlyOneCategoryInPreState(index)) {
      if (isOnlyOneCategory(index)) {
        //console.log('???=> pre: only, cur: only');
      } else {
        //console.log('???=> pre: only, cur: NOT only');
        const firstIdenticalIndex = findFirstIdenticalIndex(index);
        //console.log('???=>firstIdenticalIndex:', firstIdenticalIndex, index);
        data.mainTable[index] = {
          ...data.mainTable[index],
          categoryNum: data.mainTable[firstIdenticalIndex].categoryNum,
        };
        let standardIndex;
        if (firstIdenticalIndex < index) {
          standardIndex = index;
          const length = Object.keys(data.mainTable).length;
          for (let i = 0; i < length; i++) {
            if (
              data.mainTable[i].categoryNum >
              measuresheetData.mainTable[standardIndex].categoryNum
            ) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: data.mainTable[i].categoryNum - 1,
              };
            }
          }
        } else {
          const length = Object.keys(data.mainTable).length;
          for (let i = 0; i < length; i++) {
            if (
              Object.keys(data.mainTable[index]).every((key) => {
                if (nonComparisonElementArray.find((val) => val === key)) {
                  return true;
                } else {
                  return data.mainTable[i][key] === data.mainTable[index][key];
                }
              })
            ) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: measuresheetData.mainTable[index].categoryNum,
              };
            }
          }

          standardIndex = firstIdenticalIndex;
          for (let i = 0; i < length; i++) {
            if (
              data.mainTable[i].categoryNum >
              measuresheetData.mainTable[standardIndex].categoryNum
            ) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: data.mainTable[i].categoryNum - 1,
              };
            }
          }
        }
      }
    } else {
      if (isOnlyOneCategory(index)) {
        //console.log('???=> pre: NOT only, cur: only');
        const length = Object.keys(data.mainTable).length;
        const firstIdenticalIndexBeforeChanging =
          findFirstIdenticalIndexBeforeChanging(index);
        if (firstIdenticalIndexBeforeChanging < index) {
          for (let i = 0; i < length; i++) {
            console.log(
              '???=>i - category num:',
              data.mainTable[i].categoryNum
            );
            if (measuresheetData.mainTable[i].categoryNum > lastCategoryNum) {
              data.mainTable[i] = {
                ...data.mainTable[i],
                categoryNum: data.mainTable[i].categoryNum + 1,
              };
            }
          }
        } else {
          // in case of adding newly
          if (
            measuresheetData.mainTable[index].categoryNum === -1 &&
            !Object.keys(data.mainTable[index]).every((key) => {
              if (nonComparisonElementArray.find((val) => val === key)) {
                return true;
              } else {
                return (
                  data.mainTable[index][key] === initDataOfMeasureSheet[key]
                );
              }
            })
          ) {
            const length = Object.keys(data.mainTable).length;
            for (let i = 0; i < length; i++) {
              if (data.mainTable[i].categoryNum > lastCategoryNum) {
                data.mainTable[i] = {
                  ...data.mainTable[i],
                  categoryNum: data.mainTable[i].categoryNum + 1,
                };
              }
            }

            data.mainTable[index] = {
              ...data.mainTable[index],
              categoryNum: lastCategoryNum + 1,
            };
          } else {
            const standardIndex = findLastCategoryNumKindBeforeIndex(
              firstIdenticalIndexBeforeChanging
            );

            //console.log('???=> standardIndex:', standardIndex);

            const length = Object.keys(data.mainTable).length;
            for (let i = 0; i < length; i++) {
              // in case of init
              if (
                !Object.keys(data.mainTable[index]).every((key) => {
                  if (nonComparisonElementArray.find((val) => val === key)) {
                    return true;
                  } else {
                    return (
                      measuresheetData.mainTable[i][key] ===
                      initDataOfMeasureSheet[key]
                    );
                  }
                })
              ) {
                console.log(`???=>${i}:`, data.mainTable[i]);
                if (
                  Object.keys(data.mainTable[index]).every((key) => {
                    if (nonComparisonElementArray.find((val) => val === key)) {
                      return true;
                    } else {
                      return (
                        data.mainTable[i][key] ===
                        measuresheetData.mainTable[index][key]
                      );
                    }
                  })
                ) {
                  data.mainTable[i] = {
                    ...data.mainTable[i],
                    categoryNum: standardIndex + 1,
                  };
                } else if (data.mainTable[i].categoryNum > standardIndex) {
                  data.mainTable[i] = {
                    ...data.mainTable[i],
                    categoryNum: data.mainTable[i].categoryNum + 1,
                  };
                }
              }
            }
          }
        }
        data.mainTable[index] = {
          ...data.mainTable[index],
          categoryNum: lastCategoryNum + 1,
        };
      } else {
        //console.log('???=> pre: NOT only, cur: NOT only');
        const firstIdenticalIndex = findFirstIdenticalIndex(index);
        //console.log('???=>firstIdenticalIndex:', firstIdenticalIndex);
        data.mainTable[index] = {
          ...data.mainTable[index],
          categoryNum: data.mainTable[firstIdenticalIndex].categoryNum,
        };
      }
    }
  };

  const processWindowOrderData = () => {
    Object.keys(dataForWindowOrder.mainTable).forEach((key) => {
      dataForWindowOrder.mainTable[key] = { ...initDataOfWindowOrder };
    });

    Object.values(data.mainTable).forEach((ele) => {
      if (ele.categoryNum >= 0) {
        //console.log('!!!!!!!!!!', ele);
        dataForWindowOrder.mainTable[ele.categoryNum] = {
          ...dataForWindowOrder.mainTable[ele.categoryNum],
          categoryNum: ele.categoryNum,
          qty: parseInt(dataForWindowOrder.mainTable[ele.categoryNum].qty + 1),
          intColor: ele.intColor,
          extColor: ele.extColor,
          type: ele.style,
          szWidth: ele.orderWidth,
          szHeight: ele.orderHeight,
          energy: ele.energy,
          obsc: ele.obsc,
          temp: ele.temp,
          pattern: ele.grids,
          foam: ele.foam,
          grids: ele.grids != '' ? true : false,
        };
      }
    });

    dispatch(updateMainTableForWindowOrder(dataForWindowOrder.mainTable));
  };

  const handleSave = () => {
    let mainTable = { ...data.mainTable };
    mainTable[selectedRow] = {
      ...tempObj,
    };
    data.mainTable = mainTable;
    estimateCategoryNum(Number(selectedRow));
    dispatch(updateMainTable(data.mainTable));

    processWindowOrderData();
    setOpenTableModal(false);

    navigate('/new/customer/:customer/createcontract/measuresummary');
  };

  const handleClear = () => {
    setTempObj({ ...initDataOfMeasureSheet, no: Number(selectedRow) + 1 });
  };

  const handleChangeCheckbox = (e, value) => {
    setTempObj({ ...tempObj, foam: value });
  };

  return (
    <Box sx={{ minWidth: 120, marginBottom: 5 }}>
      <Typography
        variant="h4"
        sx={{ color: 'text.secondary', pb: 3, textAlign: 'left' }}
      >
        {`WINDOW# ${Number(selectedRow) + 1}`}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Room</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="room"
          value={tempObj.room || ''}
          label="Room"
          onChange={(e) => handleChangeInput(e)}
        >
          {roomItems.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Style</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="style"
          value={tempObj.style || ''}
          label="Style"
          onChange={(e) => handleChangeInput(e)}
        >
          {roomStyle.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="roWidth"
          value={tempObj.roWidth || ''}
          label="R.O.Width"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="roHeight"
          value={tempObj.roHeight || ''}
          label="R.O.Height"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="orderWidth"
          value={tempObj.orderWidth || ''}
          label="Order Width"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
          disabled={tempObj.style === 'SPD' ? false : true}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="orderHeight"
          value={tempObj.orderHeight || ''}
          label="Order Height"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
          disabled={tempObj.style === 'SPD' ? false : true}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="grids"
          value={tempObj.grids || ''}
          label="Grids/Blinds"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Int Color</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="intColor"
          value={tempObj.intColor || ''}
          label="Int Color"
          onChange={(e) => handleChangeInput(e)}
        >
          {interiorColor.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Ext Color</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="extColor"
          value={tempObj.extColor || ''}
          label="Ext Color"
          onChange={(e) => handleChangeInput(e)}
        >
          {exteriorColor.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox
            checked={tempObj.foam || false}
            name="foam"
            onChange={handleChangeCheckbox}
          />
        }
        label="FOAM"
        sx={{ marginBottom: 3 }}
      />
      {/* <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <label htmlFor="foam">FOAM</label>
        <div className="measure-sheet__check-box">
          <Checkbox
            checkVal={tempObj.foam}
            checkId="foam"
            updateCheck={handleChangeCheckbox}
            isInputEnable={viewMode === 'homepage'}
            type={typeOfCheckBox.PatioDoorOrder}
          />
        </div>
      </FormControl> */}
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Temp</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="temp"
          value={tempObj.temp || ''}
          label="Temp"
          onChange={(e) => handleChangeInput(e)}
        >
          {temp.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">OBSC</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="obsc"
          value={tempObj.obsc || ''}
          label="OBSC"
          onChange={(e) => handleChangeInput(e)}
        >
          {obsc.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <InputLabel id="demo-simple-select-label">Energy</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="energy"
          value={tempObj.energy || ''}
          label="Energy"
          onChange={(e) => handleChangeInput(e)}
        >
          {energy.map((value, index) => (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="mullCuts"
          value={tempObj.mullCuts || ''}
          label="Mull Cuts"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <TextField
          name="notes"
          value={tempObj.notes || ''}
          label="Notes"
          onChange={(e) => handleChangeInput(e)}
          variant="outlined"
        />
      </FormControl>
      <FormControl sx={{ marginBottom: 6 }}>
        <Button variant="contained" onClick={handleSave}>
          Save
        </Button>
      </FormControl>
    </Box>
  );
};

export default MeasureSheet;
