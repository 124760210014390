import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { data } from '../../mockup/data';
import { updateValue } from '../../store/slices/calculateSlice';
import { updateValue as updateCreditValue } from '../../store/slices/creditSlice';
import { numberWithCommas, dollarNumberWithCommas } from '../../utils/globals';

import Input from '../Input';
import Signature from '../Signature';
import RoundUpTable from '../RoundUpTable';
import PreBuiltTable from '../PreBuiltTable';
import GlassOptionTable from '../GlassOptionTable';
import WindowWorldTable from '../WindowWorldTable';
import VinylSlidingTable from '../VinylSlidingTable';
import WindowOptionTable from '../WindowOptionTable';
import MiscellenousTable from '../MiscellenousTable';
import './style.css';

const CalculateTable = (props) => {
  const {
    windowWorldTableData,
    glassTableData,
    vinylslidingTableData,
    prebuiltTableData,
    windowOptionTableData,
    miscellaneousTableData,
    roundUpTableData,
  } = data;

  const opdata = useSelector((state) => state.operation.data);
  const storeData = useSelector((state) => state.calculate.data);
  const windowWorld = useSelector((state) => state.windowworld.data);
  const viewMode = useSelector((state) => state.option.data.viewMode);
  const signStatus = useSelector((state) => state.option.data.signStatus);
  const vinylsliding = useSelector((state) => state.vinylsliding.data);
  const miscellenous = useSelector((state) => state.miscellenous.data);

  const windowTotal = Number(useSelector(
    (state) => state.windowworld.data.windowTotal///
  )) + Number(useSelector(
    (state) => state.vinylsliding.data.vinylSlidingInput1///
  )) + Number(useSelector(
    (state) => state.vinylsliding.data.vinylSlidingInput2///
  )) + Number(useSelector(
    (state) => state.vinylsliding.data.vinylSlidingInput3///
  )) + Number(useSelector(
    (state) => state.miscellenous.data.miscellenousInput13///
  ));
  const dispatch = useDispatch();
  const [contractSubTotal, setContractSubTotal] = useState(
    storeData['contractsubtotal']
  );
  const [disposalFee, setDisposalFee] = useState(storeData['calculateInput1']);
  const [measureFee, setMeasureFee] = useState(storeData['calculateInput2']);
  const [monthlyCash, setMonthlyCash] = useState(storeData['monthly1']);
  const [monthly, setMonthly] = useState(storeData['monthly2']);

  const [cc_deposit, setCCDeposit] = useState(0);

  const [windowWorldPrice, setWindowWorldPrice] = useState(0);
  const [glassOptionPrice, setGlassOptionPrice] = useState(0);
  const [vinylslidginPrice, setVinylSlidingPrice] = useState(0);
  const [prebuiltPrice, setPrebuiltPrice] = useState(0);
  const [windowOptionPrice, setWindowOptionPrice] = useState(0);
  const [miscellaneousPrice, setMiscellaneousPrice] = useState(0);
  const [roundupPrice, setRoundupPrice] = useState(0);

  const [windowTableState, setWindowTableState] =
    useState(windowWorldTableData);
  const [glassTableState, setGlassTableState] = useState(glassTableData);
  const [vinylTableState, setVinylTableState] = useState(vinylslidingTableData);
  const [prebuiltTableState, setPrebuiltTableState] =
    useState(prebuiltTableData);
  const [windowOptionTableState, setWindowOptionTableState] = useState(
    windowOptionTableData
  );
  const [miscellaneousTableState, setMiscellaneousTableState] = useState(
    miscellaneousTableData
  );
  const [roundupTableState, setRoundupTableState] = useState(roundUpTableData);

  let flag = useRef(false);

  const input1 = useRef();
  // const input2 = useRef();
  const input3 = useRef();
  const input4 = useRef();
  const input5 = useRef();

  useEffect(() => {
    const subtotal =
      roundupPrice +
      miscellaneousPrice +
      windowOptionPrice +
      prebuiltPrice +
      vinylslidginPrice +
      glassOptionPrice +
      windowWorldPrice;
    if(window.location.pathname?.split('/')[1] == 'contract') {
      console.log(opdata)
      if(opdata.operation && opdata.operation.data.calculate ) {
        setContractSubTotal(opdata.operation.data.calculate.data.contractsubtotal);
        dispatch(
          updateValue({
            id: 'contractsubtotal',
            count: opdata.operation.data.calculate.data.contractsubtotal,
          })
        );
      } else {
        setContractSubTotal(subtotal);
        dispatch(
          updateValue({
            id: 'contractsubtotal',
            count: subtotal,
          })
        );
      }
    } else {
      setContractSubTotal(subtotal);
      dispatch(
        updateValue({
          id: 'contractsubtotal',
          count: subtotal,
        })
      );
    }
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
  }, [
    roundupPrice,
    miscellaneousPrice,
    windowOptionPrice,
    prebuiltPrice,
    vinylslidginPrice,
    glassOptionPrice,
    windowWorldPrice,
  ]);

  useEffect(() => {
    if (flag.current === false) {
      input1.current.value = dollarNumberWithCommas(
        storeData['calculateInput2']
      );
      // input2.current.value = dollarNumberWithCommas(storeData['calculateInput3']);
      // input3.current.value = dollarNumberWithCommas(storeData['cc_deposit']);
      input4.current.value = dollarNumberWithCommas(storeData['cc_balance']);
      const deposit_val = cc_deposit || (Number(storeData['contractsubtotal']) + Number(storeData['calculateInput1']) + Number(storeData['calculateInput2']))/2;
      const total_val = props.isInputEnable
      ? (
          Number(contractSubTotal) +
            Number(disposalFee) +
            Number(measureFee)
        )
      : (
          Number(storeData['contractsubtotal']) +
            Number(storeData['calculateInput1']) +
            Number(storeData['calculateInput2'])
        )

      input5.current.value = dollarNumberWithCommas(
        storeData['financing'] ? (total_val - deposit_val) : storeData['calculateInput6']
      );
    }
  }, [storeData]);

  // useEffect(() => {
  //   const total = Number(storeData['contractsubtotal']) +
  //   Number(storeData['calculateInput1']) +
  //   Number(storeData['calculateInput2']);
  //   console.log(total, input3.current.value, dollarNumberWithCommas(Math.ceil(total/2)))
  //   input3.current.value = dollarNumberWithCommas(Math.ceil(total/2));
  //   dispatch(updateCreditValue({ id: 'cc_deposit', value: dollarNumberWithCommas(Math.ceil(total/2)) }));
  // }, [input3.current, storeData]);

  useEffect(() => {
    let fee = 0;
    // if (viewMode === 'homepage') {
    if (windowTotal > 0 && windowTotal <= 5) {
      fee = 75;
      setDisposalFee(fee);
    }
    if (windowTotal > 5 && windowTotal <= 10) {
      fee = 150;
      setDisposalFee(fee);
    }
    if (windowTotal > 10) {
      fee = 200;
      setDisposalFee(fee);
    }
    // dispatch(updateValue({ id: 'calculateInput1', count: fee }));
    // }

    if(windowTotal == 0) {
      let patio6ft = parseInt(vinylsliding.vinylSlidingInput1 || "0");
      let patio8ft = parseInt(vinylsliding.vinylSlidingInput2 || "0");
      let patio9ft = parseInt(vinylsliding.vinylSlidingInput3 || "0");
      let sdss = parseInt(miscellenous.miscellenousInput13 || "0");
      let sssgsd = parseInt(miscellenous.miscellenousInput14 || "0");
      let count = patio6ft + patio8ft + patio9ft + sdss + sssgsd;
      fee = 0;
      if (count > 0 && count <= 5) {
        fee = 75;
        setDisposalFee(fee);
      }
      if (count > 5 && count <= 10) {
        fee = 150;
        setDisposalFee(fee);
      }
      if (count > 10) {
        fee = 200;
        setDisposalFee(fee);
      }
      setDisposalFee(fee);
      // dispatch(updateValue({ id: 'calculateInput1', count: fee }));
      // console.log(patio6ft, patio8ft, patio9ft, sdss, sssgsd)
    }

  }, [windowTotal]);
  const stableUpdateWindowTable = (price) => {
    setWindowWorldPrice(price);
  };
  const updateWindowTable = (data) => {
    setWindowWorldPrice(data.price);

    let subtotal =
      data.price +
      glassOptionPrice +
      vinylslidginPrice +
      prebuiltPrice +
      windowOptionPrice +
      miscellaneousPrice +
      roundupPrice;

    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));

    setWindowTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  const stableUpdateGlassTable = (price) => {
    setGlassOptionPrice(price);
  };

  const updateGlassTable = (data) => {
    setGlassOptionPrice(data.price);
    let subtotal =
      windowWorldPrice +
      data.price +
      vinylslidginPrice +
      prebuiltPrice +
      windowOptionPrice +
      miscellaneousPrice +
      roundupPrice;
    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
    setGlassTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  const stableUpdateVinylTable = (price) => {
    setVinylSlidingPrice(price);
  };

  const updateVinylTable = (data) => {
    setVinylSlidingPrice(data.price);
    let subtotal =
      windowWorldPrice +
      glassOptionPrice +
      data.price +
      prebuiltPrice +
      windowOptionPrice +
      miscellaneousPrice +
      roundupPrice;
    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
    setVinylTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  const updatePrebuiltTable = (data) => {
    setPrebuiltPrice(data.price);
    let subtotal =
      windowWorldPrice +
      glassOptionPrice +
      vinylslidginPrice +
      data.price +
      windowOptionPrice +
      miscellaneousPrice +
      roundupPrice;
    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
    setPrebuiltTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  const stableUpdateWindowOptionTable = (price) => {
    setWindowOptionPrice(price);
  };

  const updateWindowOptionTable = (data) => {
    setWindowOptionPrice(data.price);
    let subtotal =
      windowWorldPrice +
      glassOptionPrice +
      vinylslidginPrice +
      prebuiltPrice +
      data.price +
      miscellaneousPrice +
      roundupPrice;
    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
    setWindowOptionTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  const updateMiscellaneousTable = (data) => {
    setMiscellaneousPrice(data.price);
    let subtotal =
      windowWorldPrice +
      glassOptionPrice +
      vinylslidginPrice +
      prebuiltPrice +
      windowOptionPrice +
      data.price +
      roundupPrice;
    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
    setMiscellaneousTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  const stableUpdateMiscellaneousTable = (price) => {
    setMiscellaneousPrice(price);
  };

  const updateRoundUpTable = (data) => {
    setRoundupPrice(data.price);
    let subtotal =
      windowWorldPrice +
      glassOptionPrice +
      vinylslidginPrice +
      prebuiltPrice +
      windowOptionPrice +
      miscellaneousPrice +
      data.price;
    setContractSubTotal(subtotal);
    dispatch(updateValue({ id: 'contractsubtotal', count: subtotal }));
    // dispatch(updateValue({ id: 'calculateInput1', count: disposalFee }));
    dispatch(updateValue({ id: 'calculateInput2', count: measureFee }));
    dispatch(updateValue({ id: 'monthly1', count: monthlyCash }));
    dispatch(updateValue({ id: 'monthly2', count: monthly }));
    setRoundupTableState((state) => {
      return state.map((item) => {
        if (item.id === data.id) {
          return { ...item, count: data.count };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    setContractSubTotal(
      windowWorldPrice +
        glassOptionPrice +
        vinylslidginPrice +
        prebuiltPrice +
        windowOptionPrice +
        miscellaneousPrice +
        roundupPrice
    );
  }, [
    windowWorldPrice,
    glassOptionPrice,
    vinylslidginPrice,
    prebuiltPrice,
    windowOptionPrice,
    miscellaneousPrice,
    roundupPrice,
  ]);

  const handleChange = (value, id) => {
    if (viewMode !== 'homepage') return;
    // if (id === 'calculateInput1') setDisposalFee(value);
    if (id === 'calculateInput2') setMeasureFee(value);
    if (id === 'cc_deposit' || id === 'cc_balance') {
      if(id === 'cc_deposit') {
        // const total = Number(storeData['contractsubtotal']) + Number(storeData['calculateInput1']) + Number(storeData['calculateInput2']);
        // input3.current.value = (value != 0 ? value : Math.ceil(total/2));
        setCCDeposit(value.replace(/\$/g, ""));
        // input3.current.value = value;
      }
      dispatch(updateCreditValue({ id: id, value: Number(value).toFixed(2) }));
    }
    if (id === 'calculateInput3')
      dispatch(updateValue({ id: id, count: value }));
    else dispatch(updateValue({ id: id, count: Number(value) }));
    if (id === 'calculateInput6')
    {
      dispatch(updateCreditValue({ id: 'cc_amount_financed', value: Number(value) }))
    }
  };

  const handleCheck = (value, { formId }) => {
    if (viewMode !== 'homepage') return;
    dispatch(updateValue({ id: formId, count: value }));
  };

  const handleSign1 = (value) => {
    dispatch(updateValue({ id: 'signature1', count: value }));
  };

  const handleSign2 = (value) => {
    dispatch(updateValue({ id: 'signature2', count: value }));
  };

  const handleDoorClick = (value) => {
    if (viewMode !== 'homepage') return;
    if (storeData[value] === '')
      dispatch(updateValue({ id: value, count: 'cross' }));
    else if (storeData[value] === 'cross')
      dispatch(updateValue({ id: value, count: 'circle' }));
    else dispatch(updateValue({ id: value, count: '' }));
  };

  const DoorHandle = ({ handleId }) => (
    <>
      {storeData[handleId] === 'cross' && (
        <img src="/images/cross.png" alt="cross" />
      )}
      {storeData[handleId] === 'circle' && (
        <img src="/images/circle.png" alt="circle" />
      )}
      {storeData[handleId] === '' && (
        <img src="/images/empty.png" alt="empty" />
      )}
    </>
  );

  const handleFocus = (e, id) => {
    e.target.type = 'number';
    e.target.value = storeData[id];
    flag.current = true;
  };

  const handleBlur = (e, id) => {
    e.target.type = 'text';
    e.target.value = '$ ' + numberWithCommas(Number(storeData[id]).toFixed(2));
    flag.current = false;
  };

  return (
    <div className="bold-letter">
      <div className="calculatetable box font-14">
        <div className="main-table table-box width-50">
          <WindowWorldTable
            data={windowTableState}
            updateWindowTable={updateWindowTable}
            stableUpdateWindowTable={stableUpdateWindowTable}
          />

          <VinylSlidingTable
            data={vinylTableState}
            updateVinylTable={updateVinylTable}
            stableUpdateVinylTable={stableUpdateVinylTable}
          />
          <div className="outsidelooking">
            <div className="wrapper">
              <div>
                <label>Color</label>
                <input
                  className="bottom-outline width-50px"
                  onChange={(e) =>
                    dispatch(
                      updateValue({ id: 'color', value: e.target.value })
                    )
                  }
                  value={storeData['color']}
                />
              </div>
              <div className="wrapper" style={{ width: '65%' }}>
                <p className="tbl-title">
                  <strong>(Outside Looking In)</strong>
                </p>
                <div>
                  <label htmlFor="doorhandle">Door Handle</label>
                  <input
                    id="doorhandle"
                    className="bottom-outline width-50px"
                    onChange={(e) =>
                      dispatch(
                        updateValue({ id: 'handle', value: e.target.value })
                      )
                    }
                    value={storeData['handle']}
                  />
                </div>
              </div>
            </div>
            <div className="wrapper">
              <div>
                <label>Initial</label>
                <Signature
                  width={95}
                  height={28}
                  signId="signature1"
                  updateSign={handleSign1}
                  imgInfo={storeData.signature1.value}
                  style={storeData.signature1.style}
                  signStatus={signStatus}
                  viewMode={viewMode}
                />
              </div>
              <div className="wrapper-center" style={{ marginTop: '2px' }}>
                <div
                  className="handle-box"
                  onClick={() => handleDoorClick('handleBox1')}
                >
                  <DoorHandle handleId="handleBox1" />
                </div>
                <div
                  className="handle-box"
                  onClick={() => handleDoorClick('handleBox2')}
                >
                  <DoorHandle handleId="handleBox2" />
                </div>
              </div>

              <div className="wrapper-center" style={{ marginTop: '2px' }}>
                <div
                  className="handle-box"
                  onClick={() => handleDoorClick('handleBox3')}
                >
                  <DoorHandle handleId="handleBox3" />
                </div>
                <div
                  className="handle-box"
                  onClick={() => handleDoorClick('handleBox4')}
                >
                  <DoorHandle handleId="handleBox4" />
                </div>
                <div
                  className="handle-box"
                  onClick={() => handleDoorClick('handleBox5')}
                >
                  <DoorHandle handleId="handleBox5" />
                </div>
              </div>
              <div>
                <label>Initial</label>
                <Signature
                  width={95}
                  height={28}
                  signId="signature2"
                  updateSign={handleSign2}
                  imgInfo={storeData.signature2.value}
                  style={storeData.signature2.style}
                  signStatus={signStatus}
                  viewMode={viewMode}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main-table table-box width-50">
          <GlassOptionTable
            data={glassTableState}
            updateGlassTable={updateGlassTable}
            stableUpdateGlassTable={stableUpdateGlassTable}
          />
          <PreBuiltTable
            data={prebuiltTableState}
            updatePrebuiltTable={updatePrebuiltTable}
          />
          <WindowOptionTable
            data={windowOptionTableState}
            updateWindowOptionTable={updateWindowOptionTable}
            stableUpdateWindowOptionTable={stableUpdateWindowOptionTable}
          />
          <MiscellenousTable
            data={miscellaneousTableState}
            updateMiscellaneousTable={updateMiscellaneousTable}
            stableUpdateMiscellaneousTable={stableUpdateMiscellaneousTable}
          />
          <RoundUpTable
            data={roundupTableState}
            updateRoundUpTable={updateRoundUpTable}
          />
        </div>
      </div>
      <div className="wrapper financing-area">
        <div className="financing-box width-40">
          <div className="wrapper payment-total">
            <div className="table-box width-50">
              <p className="financing" style={{ textAlign: 'center' }}>
                Financing
              </p>
            </div>
            <div className="table-box width-50" style={{ textAlign: 'center' }}>
              <p className="blue-text">
                <strong><input
                    id="percent"
                    className="bottom-outline width-100px medium-input"
                    type="text"
                    style={{ textAlign: 'right', width: '30px' }}
                    onChange={(e) =>
                      handleChange(e.target.value, 'percent')
                    }
                    value={numberWithCommas(Number(storeData['percent']) || 10)}
                    readOnly={viewMode !== 'homepage'}
                  />% DOWN PAYMENT</strong>
              </p>
              <input
                type="text"
                className="total-input blue-text bottom-outline width-50px"
                style={viewMode === 'convert-pdf' ? { width: '95%' } : {}}
                value={
                  props.isInputEnable
                    ? `$ ${numberWithCommas(
                        Math.round(
                          (Number(contractSubTotal) +
                            Number(disposalFee) +
                            Number(measureFee))*(Number(storeData['percent']) || 10) / 100
                            
                        )
                      )}`
                    : `$ ${numberWithCommas(
                        Math.round(
                          (Number(storeData['contractsubtotal']) +
                            Number(storeData['calculateInput1']) +
                            Number(storeData['calculateInput2'])) * (Number(storeData['percent']) || 10) / 100
                        )
                      )}`
                }
                readOnly
              />
            </div>
          </div>
          <div className="wrapper payment-total">
            <div className="table-box width-50" style={{ textAlign: 'center' }}>
              <input
                type="text"
                className="month-input bottom-outline width-50px"
                value={
                  props.isInputEnable
                  ? `$ ${numberWithCommas(
                      Math.round(
                        ((Number(contractSubTotal) +
                          Number(disposalFee) +
                          Number(measureFee)) * (Number(storeData['percent']) || 10) /
                          100) *
                          0.5003
                      )
                    )}`
                  : `$ ${numberWithCommas(
                      Math.round(
                        ((Number(storeData['contractsubtotal']) +
                          Number(storeData['calculateInput1']) +
                          Number(storeData['calculateInput2'])) *(Number(storeData['percent']) || 10) /
                          100) *
                          0.5003
                      )
                    )}`
                }
                readOnly
              />
              <label style={{ fontSize: '10px' }}>/MONTH</label>
              <p style={{ textAlign: 'center' }}>18 MONTH (0%) INTEREST DEFERRED</p>
            </div>
            <div
              className="table-box width-50"
              style={{ height: '100%', textAlign: 'center' }}
            >
              <input
                type="text"
                className="month-input bottom-outline width-50px"
                value={
                  props.isInputEnable
                      ? `$ ${numberWithCommas(
                          Math.round(
                            ((Number(contractSubTotal) +
                              Number(disposalFee) +
                              Number(measureFee)) * (Number(storeData['percent']) || 10)/
                              100) *
                              0.18
                          )
                        )}`
                      : `$ ${numberWithCommas(
                          Math.round(
                            ((Number(storeData['contractsubtotal']) +
                              Number(storeData['calculateInput1']) +
                              Number(storeData['calculateInput2'])) * (Number(storeData['percent']) || 10) /
                              100) *
                              0.18
                          )
                        )}`
                }
                readOnly
              />
              <label style={{ fontSize: '10px' }}>/MONTH</label>
              <p style={{ textAlign: 'center' }}>60 MONTHS (7.99%)</p>
            </div>
          </div>
        </div>
        <div className="total-form width-60">
          <div>
            <p>
              I am Authorized to sign and agree to the terms of payment as
              follows:
            </p>
          </div>
          <div>
            <label>Contract Sub total $</label>
            <Input
              addClass="black-text width-100px"
              type={'text'}
              style={{ textAlign: 'right' }}
              inputId="contractsubtotal"
              inputVal={
                props.isInputEnable
                  ? `$ ${numberWithCommas(contractSubTotal)}`
                  : `$ ${numberWithCommas(
                      Number(storeData['contractsubtotal'])
                    )}`
              }
              readOnlyMode={true}
            />
          </div>
          <div>
            <label
              style={
                viewMode === 'convert-pdf'
                  ? { fontSize: '12px' }
                  : { fontSize: '16px' }
              }
            >
              Site set-up, Delivery, and Disposal fee (1-5 = $75, 6-10 = $150,
              11+ = $200) $
            </label>
            <input
              id="calculateInput1"
              className="bottom-outline black-text width-100px"
              type="text"
              style={{ textAlign: 'right' }}
              value={'$ ' + storeData['calculateInput1']}
              onChange={(e) => dispatch(updateValue({ id: 'calculateInput1', count: e.target.value.replace(/[\[\]&$ ]+/g, '') }))}
              // onFocus={(e) => handleFocus(e, 'calculateInput1')}
              // onBlur={(e) => handleBlur(e, 'calculateInput1')}
              // readOnly={viewMode !== 'homepage'}
              readOnly={true}
            />
          </div>
          <div>
            <label>Measure Fee $</label>
            <input
              id="calculateInput2"
              className="bottom-outline black-text width-100px"
              type="text"
              style={{ textAlign: 'right' }}
              onChange={(e) => handleChange(e.target.value, 'calculateInput2')}
              onFocus={(e) => handleFocus(e, 'calculateInput2')}
              onBlur={(e) => handleBlur(e, 'calculateInput2')}
              ref={input1}
              defaultValue={
                '$ ' + numberWithCommas(Number(storeData['calculateInput2']))
              }
              readOnly={viewMode !== 'homepage'}
            />
          </div>
          <div>
            <label>Total Amount $</label>
            <Input
              addClass="width-100px"
              type={'text'}
              style={{ color: 'blue', textAlign: 'right' }}
              inputId="amount"
              inputVal={
                props.isInputEnable
                  ? `$ ${numberWithCommas(
                      Number(contractSubTotal) +
                        Number(disposalFee) +
                        Number(measureFee)
                    )}`
                  : `$ ${numberWithCommas(
                      Number(storeData['contractsubtotal']) +
                        Number(storeData['calculateInput1']) +
                        Number(storeData['calculateInput2'])
                    )}`
              }
              readOnlyMode={true}
            />
          </div>
          <div className="wrapper">
            <div style={{ width: viewMode === 'convert-pdf' ? '40%' : '60%' }}>
              <label>Ck#</label>
              <input
                id="calculateInput3"
                className="bottom-outline"
                style={{ width: '60%', textAlign: 'left' }}
                type="text"
                onChange={(e) =>
                  handleChange(e.target.value, 'calculateInput3')
                }
                value={storeData['calculateInput3']}
                // onFocus={(e) => handleFocus(e, 'calculateInput3')}
                // onBlur={(e) => handleBlur(e, 'calculateInput3')}
                // // defaultValue={'$ ' + storeData['calculateInput3']}
                // ref={input2}
                readOnly={viewMode !== 'homepage'}
              />
            </div>
            <div>
              <label>Custom Order Deposit {Math.floor(((storeData['cc_deposit'] || Math.round((Number(storeData['contractsubtotal']) + Number(storeData['calculateInput1']) + Number(storeData['calculateInput2']))/2))*100/(Number(storeData['contractsubtotal']) + Number(storeData['calculateInput1']) + Number(storeData['calculateInput2']))).toFixed(2))}% $</label>
              <input
                id="cc_deposit"
                className="bottom-outline width-100px"
                type="text"
                style={{ textAlign: 'right' }}
                onChange={(e) => handleChange(e.target.value, 'cc_deposit')}
                // onFocus={(e) => handleFocus(e, 'cc_deposit')}
                // onBlur={(e) => handleBlur(e, 'cc_deposit')}
                value={`$${(Number(storeData['cc_deposit']) || (Number(storeData['contractsubtotal']) + Number(storeData['calculateInput1']) + Number(storeData['calculateInput2']))/2).toFixed(2)}`}
                ref={input3}
                readOnly={viewMode !== 'homepage'}
              />
            </div>
          </div>
          <div>
            <label>Balance to be Paid to Installer $</label>
            <input
              id="cc_balance"
              className="bottom-outline width-100px"
              type="text"
              style={{ textAlign: 'right' }}
              onChange={(e) => handleChange(e.target.value, 'cc_balance')}
              onFocus={(e) => handleFocus(e, 'cc_balance')}
              onBlur={(e) => handleBlur(e, 'cc_balance')}
              ref={input4}
              readOnly={viewMode !== 'homepage'}
            />
          </div>
          <div className="wrapper">
            <div
              className="wrapper-space-between width-50"
              style={viewMode === 'convert-pdf' ? { fontSize: '12px' } : null}
            >
              <label>Payment Method</label>
              <input
                type="checkbox"
                id="cash"
                onChange={(e) =>
                  handleCheck(e.target.checked, { formId: 'cash' })
                }
                checked={storeData['cash']}
              />
              <label htmlFor="cash">CASH</label>
              <input
                type="checkbox"
                id="check"
                onChange={(e) =>
                  handleCheck(e.target.checked, { formId: 'check' })
                }
                checked={storeData['check']}
              />
              <label htmlFor="check">CHECK</label>
              <input
                type="checkbox"
                id="cc"
                onChange={(e) =>
                  handleCheck(e.target.checked, { formId: 'cc' })
                }
                checked={storeData['cc']}
              />
              <label htmlFor="cc">CC</label>
              <input
                type="checkbox"
                id="financing"
                onChange={(e) =>
                  handleCheck(e.target.checked, { formId: 'financing' })
                }
                checked={storeData['financing']}
              />
              <label htmlFor="financing">FINANCING</label>
            </div>
            <div>
              <label>Amount Financed $ </label>
              <input
                id="calculateInput6"
                className="bottom-outline width-100px"
                type="text"
                style={{ textAlign: 'right' }}
                onChange={(e) =>
                  handleChange(e.target.value, 'calculateInput6')
                }
                onFocus={(e) => handleFocus(e, 'calculateInput6')}
                onBlur={(e) => handleBlur(e, 'calculateInput6')}
                // value={numberWithCommas(Number(storeData['calculateInput6']))}
                ref={input5}
                readOnly={viewMode !== 'homepage'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CalculateTable;
