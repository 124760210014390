import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import styled from '@emotion/styled';

import { useSelector, useDispatch } from 'react-redux';
import { setUserData } from '../../store/slices/userSlice';
import { useAuthDataContext } from '../AuthProvider';
import { forgot } from '../../utils/authApi';
import { ToastContainer, toast } from 'react-toastify';

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authData, onLogin, isdemo } = useAuthDataContext();
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Provide a valid email address')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (e) => {
      const res = forgot(e)
        .then(({ data }) => {
          toast.success('Sending successed!');
          // dispatch(
          //   setUserData({ name: data.user.name, email: data.user.email })
          // );
          // onLogin(data.user);
          formik.setSubmitting(false);
          localStorage.setItem('token', data.token);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Some thing went wrong');
          formik.setSubmitting(false);
        });
    },
  });
  useEffect(() => {
    formik.setSubmitting(false);
  }, []);

  useEffect(() => {
    // if (authData.email) navigate('/documentation');
    // if (isdemo) navigate('/documentation');
    // else navigate('/');
  }, [authData]);

  const [showPassword, setShowPassword] = useState(false);

  const HeadingStyle = styled(Box)({
    textAlign: 'center',
  });

  const fadeInUp = {
    initial: {
      y: 60,
      opacity: 0,
      transition: { duration: 0.6, ease: easing },
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        ease: easing,
      },
    },
  };
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik} style={{ marginTop: '-500px' }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <HeadingStyle>
        <Typography
          variant="h3"
          sx={{ color: 'text.secondary', pb: 3, textAlign: 'left' }}
        >
          Forgot Password
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: 'text.secondary',
            mt: 4,
            mb: 3,
            textAlign: 'left',
            fontSize: 'medium',
          }}
        >
          Please send a code to your email
        </Typography>
      </HeadingStyle>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email Address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Box>
            <br/>
          <Box initial={{ opacity: 0, y: 20 }} animate={animate}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {isSubmitting ? 'loading...' : 'Send'}
            </LoadingButton>
          </Box>
        </Box>
      </Form>
      <Typography
        {...fadeInUp}
        variant="body2"
        align="center"
        sx={{ mt: 3 }}
      ></Typography>
    </FormikProvider>
  );
};

export default LoginForm;
